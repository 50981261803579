import mitt from 'mitt';

const emitter = mitt();

emitter.$on = emitter.on.bind(emitter);
emitter.$off = emitter.off.bind(emitter);
emitter.$emit = emitter.emit.bind(emitter);

export default defineNuxtPlugin((nuxt) => {
  nuxt.provide('eventBus', emitter);
  nuxt.provide('root', emitter);
});
