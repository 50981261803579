import { mapGetters } from 'vuex';

export default {
  name: 'FormFlowApiMixin',
  computed: {
    ...mapGetters('ModalStore', ['modalData']),
  },
  methods: {
    async fetchGroupReporters(groupId) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/users/groups/${groupId}`, {
        baseURL: this.$baseURL,
        headers,
      });
    },
    async fetchFormFlowSteps(formFlow, urlParameters) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/location/${formFlow.location.id}/form-flows/${formFlow.id}/steps`,
        {
          baseURL: this.$baseURL,
          headers,
          params: urlParameters,
        },
      );
    },
    async fetchFormFlowItemWithSubmissions(locationId, flowId, itemId) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/location/${locationId}/form-flows/${flowId}/items/${itemId}?embed=submissions`,
        { baseURL: this.$baseURL, headers },
      );
    },
    async fetchFormFlowFilledSteps(formFlow, location, flowLocation) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/location/${location.id}/form-flows/${flowLocation.id}/items/${formFlow.id}/steps`,
        { baseURL: this.$baseURL, headers },
      );
    },
    async fetchForms(location, urlParameters) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/location/${location.id}/form-flows`, {
        baseURL: this.$baseURL,
        headers,
        params: urlParameters,
      });
    },
    async getByUrl(url) {
      const headers = useRequestHeaders(['cookie']);
      const { pathname, search } = new URL(url);

      return await $fetch(`${pathname}${search}`, {
        baseURL: this.$baseURL,
        headers,
      });
    },
    async attachFileToForm(formFlow, location, file) {
      const headers = useRequestHeaders(['cookie']);

      const formData = new FormData();
      formData.append('file', file);

      return await $fetch(
        `/api/v2/location/${location.id}/form-flows/${formFlow.id}/files`,
        {
          method: 'POST',
          baseURL: this.$baseURL,
          headers,
          body: formData,
        },
      );
    },
    async fetchFormFlows(location, form, urlParameters) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/location/${location.id}/form-flows/${form.id}/items`,
        {
          baseURL: this.$baseURL,
          headers,
          params: urlParameters,
        },
      );
    },
    async createFormFlowItem(location, form) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/location/${location}/form-flows/${form}/items`,
        { method: 'POST', baseURL: this.$baseURL, headers },
      );
    },
    async createNewFormFlowStepSubmission(location, flow, step, item, payload) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/location/${location}/form-flows/${flow}/items/${item}/steps/${step}/submissions`,
        {
          method: 'POST',
          baseURL: this.$baseURL,
          headers,
          body: payload,
        },
      );
    },
    async updateFormFlowStepSubmission(
      location,
      flow,
      step,
      item,
      submission,
      payload,
    ) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/location/${location}/form-flows/${flow}/items/${item}/steps/${step}/submissions/${submission}`,
        {
          method: 'PATCH',
          baseURL: this.$baseURL,
          headers,
          body: payload,
        },
      );
    },
    async deleteFormFlowItem(formFlow, item) {
      const headers = useRequestHeaders(['cookie']);

      try {
        return await $fetch(
          `/api/v2/location/${formFlow.location.id}/form-flows/${formFlow.id}/items/${item.id}`,
          { method: 'DELETE', baseURL: this.$baseURL, headers },
        );
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong'),
          message: this.__('Failed to delete form'),
          autoDismiss: 5000,
        });
      }
    },
  },
};
