export default {
  namespaced: true,
  state: () => ({
    statuses: [],
    groups: [],
    authorizations: [],
  }),
  getters: {
    statuses(state) {
      return state.statuses;
    },
    groups(state) {
      return state.groups;
    },
    authorizations(state) {
      return state.authorizations;
    },
  },
  mutations: {
    setGroups: (state, groups) => {
      state.groups = groups;
    },

    setStatuses: (state, statuses) => {
      state.statuses = statuses;
    },

    setAuthorizations: (state, authorizations) => {
      state.authorizations = authorizations;
    },
  },
};
