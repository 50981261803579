import { mapGetters } from 'vuex';

import FormTemplateApiMixin from './FormTemplateApiMixin';
import LocationsControllerMixin from '@/mixins/Locations/LocationsControllerMixin';
import FormFlowApiMixin from '~/features/form-flows/mixins/FormFlowApiMixin';

export default {
  name: 'FormTemplateManager',
  mixins: [FormTemplateApiMixin, FormFlowApiMixin, LocationsControllerMixin],
  computed: {
    ...mapGetters('FormTemplateModuleStore', [
      'sortingParams',
      'formTemplateData',
    ]),
  },
  methods: {
    setDefaultSortingParams() {
      this.setSortingParams({
        per_page: 100,
      });
    },
    setSortingParams(sortParameters) {
      this.$store.commit(
        'FormTemplateModuleStore/setSortingParams',
        sortParameters,
      );
    },
    async retrieveFormTemplate(formTemplateId) {
      try {
        return await this.getFormTemplate(
          this.getSelectedLocation(),
          formTemplateId,
        );
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__('Failed to receive form template.'),
          autoDismiss: 5000,
        });
      }
    },
    async retrieveFormTemplates() {
      if (this.getSelectedLocation()) {
        try {
          const response = await this.getFormTemplates(
            this.getSelectedLocation(),
            this.sortingParams,
          );
          this.$store.commit(
            'FormTemplateModuleStore/setFormTemplates',
            response.data,
          );
        }
        catch {
          this.$toasty.$error({
            title: this.__('Something went wrong!'),
            message: this.__('Failed to receive form templates.'),
            autoDismiss: 5000,
          });
        }
      }
    },
    async retrieveFormTemplatesByLocation(locationId) {
      try {
        const response = await this.getFormTemplates(
          { id: locationId },
          this.sortingParams,
        );
        return response.data;
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__('Failed to receive form templates.'),
          autoDismiss: 5000,
        });
      }
    },
    async removeFormTemplate(formFlowId) {
      try {
        await this.deleteFormTemplate(this.getSelectedLocation(), formFlowId);
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__('Failed to delete form template.'),
          autoDismiss: 5000,
        });
      }
    },
    async createFormTemplate(payload) {
      try {
        return await this.postFormTemplate(this.getSelectedLocation(), payload);
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__('Failed to create form template.'),
          autoDismiss: 5000,
        });
      }
    },
    async formTemplateCopy(formId, payload) {
      try {
        return await this.copyFormTemplate(
          this.getSelectedLocation(),
          formId,
          payload,
        );
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__('Failed to copy form template.'),
          autoDismiss: 5000,
        });

        return false;
      }
    },
    async updateFormTemplate(formId, payload) {
      try {
        return await this.patchFormTemplate(
          this.getSelectedLocation(),
          formId,
          payload,
        );
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__('Failed to update form template.'),
          autoDismiss: 5000,
        });

        return false;
      }
    },
    async createFormTemplateStep(payload) {
      try {
        return await this.postNewStep(this.formTemplateData, payload);
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__('Failed to create form template step.'),
          autoDismiss: 5000,
        });
      }
    },
    async deleteFormTemplateStep(stepId) {
      try {
        return await this.deleteStep(this.formTemplateData, stepId);
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__('Failed to delete form template step.'),
          autoDismiss: 5000,
        });
      }
    },
    async updateFormTemplateStep(payload) {
      try {
        return await this.updateStep(this.formTemplateData, payload);
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__('Failed to update form template step.'),
          autoDismiss: 5000,
        });
      }
    },
    async updateFormTemplateStepOrder(payload) {
      try {
        return await this.updateStepOrder(this.formTemplateData, payload);
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__('Failed to change template steps order.'),
          autoDismiss: 5000,
        });
      }
    },
  },
};
