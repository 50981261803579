export default {
  namespaced: true,
  state: () => ({
    dropdownOpened: '',
    dropdownType: '',
    dropdownLabel: '',
    dropdownCurrentContext: '',
    dropdownSecondaryLabel: '',
    dropdownModule: '',
    dropdownOptions: [],
    dropdownSecondaryOptions: [],
    dropdownChecked: {},
    dropdownTempChecked: {},
    dropdownSecondaryChecked: {},
    optionsWithImage: false,
    optionIcon: false,
    optionIconType: '',
    contextData: {},
    customEvents: [],
    callbacks: {},
  }),
  getters: {
    customEvent: state => (name) => {
      return state.customEvents.find(event => event.name === name);
    },
    customEvents(state) {
      return state.customEvents;
    },
    contextData(state) {
      return state.contextData;
    },
    dropdownOpened(state) {
      return state.dropdownOpened;
    },
    dropdownType(state) {
      return state.dropdownType;
    },
    dropdownLabel(state) {
      return state.dropdownLabel;
    },
    dropdownSecondaryLabel(state) {
      return state.dropdownSecondaryLabel;
    },
    dropdownOptions(state) {
      return state.dropdownOptions;
    },
    dropdownSecondaryOptions(state) {
      return state.dropdownSecondaryOptions;
    },
    dropdownChecked(state) {
      return state.dropdownChecked;
    },
    dropdownTempChecked(state) {
      return state.dropdownTempChecked;
    },
    dropdownSecondaryChecked(state) {
      return state.dropdownSecondaryChecked;
    },
    dropdownModule(state) {
      return state.dropdownModule;
    },
    optionsWithImage(state) {
      return state.optionsWithImage;
    },
    optionIcon(state) {
      return state.optionIcon;
    },
    optionIconType(state) {
      return state.optionIconType;
    },
    onOptionChangedCallback(state) {
      return state.callbacks.onOptionChangedCallback;
    },
    onSecondaryOptionChangedCallback(state) {
      return state.callbacks.onSecondaryOptionChangedCallback;
    },
    dropdownCurrentContext(state) {
      return state.dropdownCurrentContext;
    },
  },
  mutations: {
    registerCustomEvent: (state, customEvent) => {
      state.customEvents.push(customEvent);
    },
    setCustomEvents: (state, customEvents) => {
      state.customEvents = customEvents;
    },
    setContextData: (state, contextData) => {
      state.contextData = contextData;
    },
    setDropdownOpened: (state, dropdownOpened) => {
      state.dropdownOpened = dropdownOpened;
      if (!dropdownOpened) {
        state.customEvents = [];
        state.optionIcon = false;
        state.optionsWithImage = false;
        state.optionIconType = '';
        state.dropdownChecked = {};
        state.dropdownLabel = '';
        state.dropdownSecondaryLabel = '';
        state.callbacks = {};
      }
    },
    setCurrentDropdownContext: (state, currentContext) => {
      state.dropdownCurrentContext = currentContext;
    },
    setDropdownType: (state, dropdownType) => {
      state.dropdownType = dropdownType;
    },
    setDropdownModule: (state, dropdownModule) => {
      state.dropdownModule = dropdownModule;
    },
    setDropdownLabel: (state, dropdownLabel) => {
      state.dropdownLabel = dropdownLabel;
    },
    setDropdownSecondaryLabel: (state, dropdownSecondaryLabel) => {
      state.dropdownSecondaryLabel = dropdownSecondaryLabel;
    },
    setDropdownOptions: (state, dropdownOptions) => {
      state.dropdownOptions = dropdownOptions;
    },
    setDropdownSecondaryOptions: (state, dropdownSecondaryOptions) => {
      state.dropdownSecondaryOptions = dropdownSecondaryOptions;
    },
    setDropdownChecked: (state, dropdownChecked) => {
      state.dropdownChecked = dropdownChecked;
    },
    setDropdownTempChecked: (state, dropdownTemporaryChecked) => {
      state.dropdownTempChecked = dropdownTemporaryChecked;
    },
    setDropdownSecondaryChecked: (state, dropdownSecondaryChecked) => {
      state.dropdownSecondaryChecked = dropdownSecondaryChecked;
    },
    setOptionIcon: (state, optionIcon) => {
      state.optionIcon = optionIcon;
    },
    setOptionIconType: (state, optionIconType) => {
      state.optionIconType = optionIconType;
    },
    setOptionsWithImage: (state, optionsWithImage) => {
      state.optionsWithImage = optionsWithImage;
    },
    setOnOptionChangedCallback: (state, callback) => {
      state.callbacks.onOptionChangedCallback = callback;
    },
    setOnSecondaryOptionChangedCallback: (state, callback) => {
      state.callbacks.onSecondaryOptionChangedCallback = callback;
    },
  },
};
