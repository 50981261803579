export default defineNuxtComponent({
  inject: ['mq'],
  computed: {
    getDisplayMode() {
      return this.mq.current;
    },
  },
  watch: {
    mq(newValue) {
      this.$eventBus.$emit('displayChanged', newValue);
    },
  },
  methods: {
    fetchDisplayFromMq() {
      return this.mq.current;
    },
    computedContainerCorrection(baseSize, extendedSize, useExtended) {
      const containerSize = useExtended ? extendedSize : baseSize;
      return {
        '--containerCorrection': `${containerSize}px`,
      };
    },
  },
});
