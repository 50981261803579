/**
 * --------------------------------------------------------------------
 * Registration store
 * --------------------------------------------------------------------
 */

export default {
  namespaced: true,
  state: () => ({
    deleteRegistrationTrigger: false,
    signOutTrigger: false,
    signInPayload: {},
    registrationPayload: {},
    announcementPersons: [],
    announcements: [],
    signedOutVisitors: [],
    signedInVisitors: [],
    visitorTypes: [],
    groupIdSelected: undefined,
    keys: [],
    badges: [],
    registrationErrorTrigger: {},
  }),
  getters: {
    registrationErrorTrigger(state) {
      return state.registrationErrorTrigger;
    },
    keys(state) {
      return state.keys;
    },
    badges(state) {
      return state.badges;
    },
    groupIdSelected(state) {
      return state.groupIdSelected;
    },
    visitorTypes(state) {
      return state.visitorTypes;
    },
    announcementPersons(state) {
      return state.announcementPersons;
    },
    announcedvisitors(state) {
      return state.announcedVisitors || 0;
    },
    signedOutVisitors(state) {
      return state.signedOutVisitors;
    },
    signedInVisitors(state) {
      return state.signedInVisitors;
    },
    registrationPayload(state) {
      return state.registrationPayload;
    },
    announcements(state) {
      return state.announcements;
    },
    signInPayload(state) {
      return state.signInPayload;
    },
    deleteRegistrationTrigger(state) {
      return state.deleteRegistrationTrigger;
    },
    signOutTrigger(state) {
      return state.signOutTrigger;
    },
  },
  mutations: {
    registrationErrorTrigger: (state, registrationErrorTrigger) =>
      (state.registrationErrorTrigger = registrationErrorTrigger),
    setKeys: (state, keys) => (state.keys = keys),
    setBadges: (state, badges) => (state.badges = badges),
    setGroupIdSelected: (state, groupIdSelected) =>
      (state.groupIdSelected = groupIdSelected),
    setVisitorTypes: (state, visitorTypes) =>
      (state.visitorTypes = visitorTypes),
    setAnnouncementPersons: (state, persons) =>
      (state.announcementPersons = persons),
    setSignedOutVisitors: (state, signedOutVisitors) =>
      (state.signedOutVisitors = signedOutVisitors),
    setSignedInVisitors: (state, signedInVisitors) =>
      (state.signedInVisitors = signedInVisitors),
    setAnnouncedVisitors: (state, announcedVisitors) =>
      (state.announcedVisitors = announcedVisitors),
    setDescription: (state, description) =>
      (state.signInPayload.description = description),
    setIncludingWeekend: (state, includingWeekend) =>
      (state.signInPayload.including_weekend = includingWeekend),
    setMultipleEntrance: (state, multipleEntrance) =>
      (state.signInPayload.multiple_entrance = multipleEntrance),

    setDateFrom: (state, date) => (state.signInPayload.date_from = date),
    setDateTill: (state, date) => (state.signInPayload.date_till = date),
    setLocationId: (state, locationId) =>
      (state.signInPayload.location_id = locationId),
    deleteRegistrationTrigger: state =>
      (state.deleteRegistrationTrigger = true),
    signOutTrigger: state => (state.signOutTrigger = true),
    setAnnouncements(state, announcements) {
      state.announcements = announcements;
    },

    setAnnouncementId: (state, id) =>
      (state.registrationPayload.announcement_id = id),
    setHostId: (state, id) => (state.registrationPayload.host_id = id),
    setAuthorizationsIssue(state, authorizationsIssue) {
      state.signInPayload = {
        ...state.signInPayload,
        authorizationsIssue,
      };
      state.registrationPayload = {
        ...state.signInPayload,
        authorizationsIssue,
      };
    },
    setAuthorizationsOwn(state, authorizationsOwn) {
      state.signInPayload.authorizationsOwn = authorizationsOwn;
      state.registrationPayload.authorizationsOwn = authorizationsOwn;
    },
    setOwnerId(state, id) {
      state.signInPayload.owner_id = id;
      state.registrationPayload.owner_id = id;
    },
    addItemToPerson(state, { visitor, item }) {
      const indexOfPerson = state.registrationPayload.persons.findIndex(
        pers => pers.user_id === visitor.id,
      );
      if (indexOfPerson === -1)
        return;

      if (state.registrationPayload.persons[indexOfPerson].keys === undefined)
        state.registrationPayload.persons[indexOfPerson].keys = [];

      if (
        state.registrationPayload.persons[indexOfPerson].badges === undefined
      )
        state.registrationPayload.persons[indexOfPerson].badges = [];

      const addToKeys = item.type === 'Keys';
      if (addToKeys)
        state.registrationPayload.persons[indexOfPerson].keys.push(item.id);
      else
        state.registrationPayload.persons[indexOfPerson].badges.push(item.id);
    },
    removeItemFromPerson(state, { visitor, item }) {
      const indexOfPerson = state.registrationPayload.persons.findIndex(
        pers => pers.user_id === visitor.id,
      );
      if (indexOfPerson === -1)
        return;

      const addToKeys = item.type === 'Keys';

      if (addToKeys) {
        const itemIndex = state.registrationPayload.persons[
          indexOfPerson
        ].keys.indexOf(item.id);
        state.registrationPayload.persons[indexOfPerson].keys.splice(
          itemIndex,
          1,
        );
      }
      else {
        const itemIndex = state.registrationPayload.persons[
          indexOfPerson
        ].badges.indexOf(item.id);
        state.registrationPayload.persons[indexOfPerson].keys.badges(
          itemIndex,
          1,
        );
      }
    },
    addPerson(state, person) {
      if (state.signInPayload.persons === undefined)
        state.signInPayload.persons = [];

      if (state.registrationPayload.persons === undefined)
        state.registrationPayload.persons = [];

      state.signInPayload.persons.push(person);
      state.signInPayload = {
        ...state.signInPayload,
        persons: state.signInPayload.persons,
      };

      state.registrationPayload.persons.push(person);
    },
    removePerson(state, personId) {
      if (state.signInPayload.persons.length === 0)
        return;

      const index = state.signInPayload.persons.findIndex(
        person => person.id === personId,
      );
      state.signInPayload.persons.splice(index, 1);
    },
    setGroupId(state, groupId) {
      state.signInPayload.group_id = groupId;
      state.registrationPayload.group_id = groupId;
    },
    clearRegistrationStore: (state) => {
      state.groupIdSelected = undefined;
      state.registrationErrorTrigger = undefined;
      state.signInPayload = {};
      state.registrationPayload = {};
      state.deleteRegistrationTrigger = false;
      state.signOutTrigger = false;
    },
  },
};
