export default {
  namespaced: true,
  state() {
    return {
      buildInfo: {
        date: undefined,
        hash: undefined,
      },
      environment: 'local',
      initialized: false,
      layout: {
        favIcon: undefined,
        logoSmall: undefined,
        logoBig: undefined,
      },
      modules: {},
      name: undefined,
      title: undefined,
      accentColor: '#0080FF',
      modeType: 'Work mode',
      darkMode: false,
      activeNavigationKey: 0,
      currentContextPopup: '',
      userAbilities: undefined,
    };
  },
  actions: {
    async fetchContext({ commit }) {
      const headers = useRequestHeaders(['cookie']);

      try {
        const {
          data: { layout, app, modules },
        } = await $fetch(`/api/v2/context`, {
          baseURL: this.$baseURL,

          headers,
        });

        commit('contextFetched', {
          title: app.title || this.$config.APP_TITLE,
          layout,
          modules,
        });
      }
      catch {}
    },
    setContext({ commit }) {
      const { BUILD_HASH, APP_ENV, BUILD_DATE, APP_NAME } = this.$config;

      commit('contextFetched', {
        buildInfo: {
          date: BUILD_DATE,
          hash: BUILD_HASH,
        },
        environment: APP_ENV,
        name: APP_NAME,
      });
    },
  },
  getters: {
    idp: state => state.modules.idp || [],
    userAbilities: state => state.userAbilities,
    accentColor: state => state.accentColor,
  },
  mutations: {
    contextFetched: (state, payload) => {
      Object.assign(state, { ...payload, initialized: true });
    },

    setModeType: (state, modeType) => (state.modeType = modeType),

    setActiveNavigationKey: (state, activeNavigationKey) =>
      (state.activeNavigationKey = activeNavigationKey),

    setDarkMode: (state, darkMode) => (state.darkMode = darkMode),

    setCurrentContextPopup: (state, currentContextPopup) =>
      (state.currentContextPopup = currentContextPopup),

    userAbilitiesFetched: (state, payload) => {
      Object.assign(state, { userAbilities: payload });
    },
  },
};
