/**
 * --------------------------------------------------------------------
 * Location over application store
 * --------------------------------------------------------------------
 */

export default {
  namespaced: true,
  state: () => ({
    selectedLocation: {},
    locations: [],
    groups: [],
    groupsAndLocations: [],
    nfc: [],
  }),
  actions: {
    async fetchNFC({ commit }, location) {
      const headers = useRequestHeaders(['cookie']);

      try {
        const {
          data: { nfc },
        } = await $fetch(`/api/v2/location/${location.id}/cached-data`, {
          baseURL: this.$baseURL,

          headers,
        });

        commit('setNFC', {
          nfc,
        });
      }
      catch {}
    },
  },
  getters: {
    selectedLocation(state) {
      return state.selectedLocation;
    },
    locations(state) {
      return state.locations;
    },
    groups(state) {
      return state.groups;
    },
    groupsAndLocations(state) {
      return state.groupsAndLocations;
    },
    nfcTags(state) {
      return state.nfc;
    },
  },
  mutations: {
    setSelectedLocation: (state, selectedLocation) => {
      state.selectedLocation = selectedLocation;
    },
    setGroupsAndLocations: (state, groupsAndLocations) => {
      state.groupsAndLocations = groupsAndLocations;
    },
    setGroups: (state, groups) => {
      state.groups = groups;
    },
    addLocation: (state, location) => {
      state.locations.push(location);
    },
    setLocations: (state, locations) => {
      state.locations = locations;
    },
    setNFC: (state, nfc) => {
      Object.assign(state, { ...nfc, initialized: true });
    },
  },
};
