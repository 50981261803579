/**
 * --------------------------------------------------------------------
 * Tickets module store
 * --------------------------------------------------------------------
 */
import { cloneDeep } from 'lodash-es';

export default {
  namespaced: true,
  state: () => ({
    fetchedTickets: { data: [], meta: { size: 0 } },
    selectedTickets: [],
    priorities: [],
    users: [],
    groups: [],
    types: [],
    statuses: [],
    assignees: [],
    escalations: [],
    ticketPayload: {},
    payloadSnapshot: {},
    sortingParams: {},
    processingAction: false,
    ticketErrorTrigger: {},
    validations: {
      required: [],
      validForm: true,
    },
  }),
  getters: {
    fetchedTickets(state) {
      return state.fetchedTickets;
    },
    selectedTickets(state) {
      return state.selectedTickets;
    },
    priorities(state) {
      return state.priorities;
    },
    users(state) {
      return state.users;
    },
    groups(state) {
      return state.groups;
    },
    types(state) {
      return state.types;
    },
    statuses(state) {
      return state.statuses;
    },
    assignees(state) {
      return state.assignees;
    },
    ticketPayload(state) {
      return state.ticketPayload;
    },
    escalations(state) {
      return state.escalations;
    },
    sortingParams(state) {
      return state.sortingParams;
    },
    payloadSnapshot(state) {
      return state.payloadSnapshot;
    },
    processingAction(state) {
      return state.processingAction;
    },
    validations(state) {
      return state.validations;
    },
    ticketErrorTrigger(state) {
      return state.ticketErrorTrigger;
    },
  },
  mutations: {
    setSortingParams: (state, parameters) => {
      state.sortingParams = parameters;
    },
    fetchedTickets: (state, fetchedTickets) =>
      (state.fetchedTickets = fetchedTickets),
    setSelectedTickets: (state, selectedTickets) =>
      (state.selectedTickets = selectedTickets),
    addSelectedTickets: (state, selectedTickets) =>
      state.selectedTickets.push(selectedTickets),
    removeSelectedTickets: (state, selectedTickets) =>
      state.selectedTickets.splice(selectedTickets, 1),
    setPriorities: (state, priorities) => {
      state.priorities = priorities;
    },
    setAssignees: (state, assignees) => {
      state.assignees = assignees;
    },
    setUsers: (state, users) => {
      state.users = users;
    },
    setGroups: (state, groups) => {
      state.groups = groups;
    },
    setTypes: (state, types) => {
      state.types = types;
    },
    setStatuses: (state, statuses) => {
      state.statuses = statuses;
    },
    setEscalations: (state, escalations) => {
      state.escalations = escalations;
    },
    setPayload: (state, payload) => (state.ticketPayload = payload),
    setPayloadField: (state, { key, value }) => {
      state.ticketPayload = {
        ...cloneDeep(state.ticketPayload),
        [key]: value,
      };
    },
    setPayloadSnapshot: (state, payloadSnapshot) => {
      state.payloadSnapshot = payloadSnapshot;
    },
    takePayloadSnapshot(state) {
      state.payloadSnapshot = state.ticketPayload;
    },
    setTicketErrorTrigger: (state, error) => (state.ticketErrorTrigger = error),
    setProcessingAction: (state, processingAction) =>
      (state.processingAction = processingAction),
    setValidations: (state, validations) =>
      (state.validations.required = validations),
    setValidationsValidForm: (state, validForm) =>
      (state.validations.validForm = validForm),
  },
};
