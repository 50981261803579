import { mapGetters } from 'vuex';

export default defineNuxtComponent({
  name: 'SidebarMixin',
  computed: {
    ...mapGetters('SidebarStore', [
      'firstLevelSidebarIsCollapsed',
      'secondLevelSidebarIsCollapsed',
    ]),
    isSidebarCollapsed() {
      return this.firstLevelSidebarIsCollapsed;
    },
    isSecondSidebarCollapsed() {
      return this.secondLevelSidebarIsCollapsed;
    },
  },
  methods: {
    setSidebarIsCollapsed(value) {
      this.$store.commit('SidebarStore/setFirstLevelSidebarIsCollapsed', value);
    },
    setSecondSidebarIsCollapsed(value) {
      this.$store.commit(
        'SidebarStore/setSecondLevelSidebarIsCollapsed',
        value,
      );
    },
    setSelectedNavigationItem(item) {
      this.$store.commit('SidebarStore/setSelectedNavigationItem', item);
    },
  },
});
