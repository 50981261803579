/**
 * --------------------------------------------------------------------
 * Form Templates module store
 * --------------------------------------------------------------------
 */

export default {
  namespaced: true,
  state: () => ({
    formTemplates: [],
    sortingParams: {},
    formTemplateData: {},
    editableSteps: [],
  }),
  getters: {
    sortingParams(state) {
      return state.sortingParams;
    },
    formTemplateData(state) {
      return state.formTemplateData;
    },
    editableSteps(state) {
      return state.editableSteps;
    },
    formTemplates(state) {
      return state.formTemplates;
    },
  },
  mutations: {
    setSortingParams: (state, sortingParameters) =>
      (state.sortingParams = sortingParameters),
    setFormTemplates: (state, formTemplates) =>
      (state.formTemplates = formTemplates),
    setFormTemplateData: (state, formTemplateData) =>
      (state.formTemplateData = formTemplateData),
    setFormTemplateSettings: (state, formTemplateSettings) => {
      state.formTemplateData = {
        ...state.formTemplateData,
        settings: formTemplateSettings,
      };
    },
    setEditableSteps: (state, editableSteps) =>
      (state.editableSteps = editableSteps),
    addEditableStep: (state, step) =>
      (state.editableSteps = [...state.editableSteps, step]),
    addElementToStep: (state, { stepIndex, element }) =>
      state.editableSteps[stepIndex].elements.push(element),
    resetFormsTemplate: (state) => {
      state.editableSteps = [];
    },
  },
};
