import { vOnClickOutside } from '@vueuse/components';
import { sprintf } from 'sprintf-js';
import { Vue3Mq } from 'vue3-mq';

import PerfectScrollbar from 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.esm';

export default defineNuxtPlugin((nuxt) => {
  const { $store } = useNuxtApp();
  nuxt.vueApp.directive('click-outside', vOnClickOutside);
  nuxt.vueApp.use(PerfectScrollbar);

  nuxt.vueApp.use(Vue3Mq, {
    breakpoints: {
      // default breakpoints - customize this
      mobile: 0,
      small_desktop: 1200,
      desktop: 1440,
    },
  });

  const translate = $store.getters['TranslationStore/getTranslation'];

  nuxt.vueApp.config.globalProperties.__ = translate;

  nuxt.vueApp.config.globalProperties.sprintf = sprintf;
});
