const appContext = ref({
  app: {},
  buildInfo: {
    date: undefined,
    hash: undefined,
  },
  environment: 'local',
  initialized: false,
  layout: {
    background: undefined,
    favIcon: undefined,
    logoSmall: undefined,
    logoBig: undefined,
  },
  modules: {
    accessrequest: {
      hostOpenTextField: false,
    },
  },
  name: undefined,
  title: undefined,
  accentColor: '#0080FF',
  modeType: 'Work mode',
  darkMode: false,
  activeNavigationKey: 0,
  currentContextPopup: '',
  userAbilities: undefined,
});

export function useAppContext() {
  const {
    public: { APP_TITLE },
  } = useRuntimeConfig();
  const { $baseURL } = useNuxtApp();

  async function fetchAppContext() {
    const headers = useRequestHeaders(['cookie']);
    const { data } = await $fetch<any>('/api/v2/context', {
      baseURL: $baseURL,
      headers,
    });

    appContext.value = {
      ...appContext.value,
      app: data.app,
      title: data.app.title || APP_TITLE,
      layout: data.layout,
      modules: data.modules,
    };
  }

  return {
    appContext,
    fetchAppContext,
  };
}
