import { cloneDeep } from 'lodash-es';

/**
 * --------------------------------------------------------------------
 * Form Flows module store
 * --------------------------------------------------------------------
 */

export default {
  namespaced: true,
  state: () => ({
    contextFormFlowItem: {},
    filledFormFlowSelections: {},
    filledSelectionsSnapshot: {},
    selectedFormFlowLocationForm: undefined,
    selectedStep: {},
    fetchedLocationForms: [],
    formFlowSteps: [],
    formFlowSubmissions: [],
    formFlows: { data: [], meta: { total: 0 } },
    selectedFormFlow: undefined,
    selectedFormFlowItems: [],
    reporters: [],
    formFlowPayload: {},
    sortingParams: {},
    processingAction: false,
    validations: {
      required: [],
      validForm: false,
    },
  }),
  getters: {
    reporters(state) {
      return state.reporters;
    },
    fetchedLocationForms(state) {
      return state.fetchedLocationForms;
    },
    selectedFormFlowLocationForm(state) {
      return state.selectedFormFlowLocationForm;
    },
    selectedStep(state) {
      return state.selectedStep;
    },
    formFlowSteps(state) {
      return state.formFlowSteps;
    },
    formFlows(state) {
      return state.formFlows;
    },
    formFlowSubmissions(state) {
      return state.formFlowSubmissions;
    },
    selectedFormFlow(state) {
      return state.selectedFormFlow;
    },
    selectedFormFlowItems(state) {
      return state.selectedFormFlowItems;
    },
    validations(state) {
      return state.validations;
    },
    formFlowPayload(state) {
      return state.formFlowPayload;
    },
    sortingParams(state) {
      return state.sortingParams;
    },
    filledFormFlowSelections(state) {
      return state.filledFormFlowSelections;
    },
    filledSelectionsSnapshot(state) {
      return state.filledSelectionsSnapshot;
    },
    contextFormFlowItem(state) {
      return state.contextFormFlowItem;
    },
    processingAction(state) {
      return state.processingAction;
    },
  },
  mutations: {
    setFetchedLocationForms: (state, fetchedLocationForms) =>
      (state.fetchedLocationForms = fetchedLocationForms),
    setSelectedFormFlowLocationForm: (state, selectedFormFlowLocationForm) =>
      (state.selectedFormFlowLocationForm = selectedFormFlowLocationForm),
    setFormFlows: (state, formFlows) => (state.formFlows = formFlows),
    setSelectedFormFlow: (state, selectedFormFlow) =>
      (state.selectedFormFlow = selectedFormFlow),
    setSelectedStep: (state, selectedStep) =>
      (state.selectedStep = selectedStep),
    setFormFlowSteps: (state, formFlowSteps) =>
      (state.formFlowSteps = formFlowSteps),
    setFormFlowSubmissions: (state, formFlowSubmissions) =>
      (state.formFlowSubmissions = formFlowSubmissions),
    setSelectedFormFlowItems: (state, selectedFormFlowItems) =>
      (state.selectedFormFlowItems = selectedFormFlowItems),
    setReporters: (state, reporters) => (state.reporters = reporters),
    setValidations: (state, validations) =>
      (state.validations.required = validations),
    setValidationsValidForm: (state, validForm) =>
      (state.validations.validForm = validForm),
    clearFilledFormFlowSelections: (state) => {
      state.filledFormFlowSelections = {};
    },
    takeFilledFormFlowSnapshot: (state) => {
      state.filledSelectionsSnapshot = state.filledFormFlowSelections;
    },
    setFilledFormFlowSelections: (state, { key, value }) => {
      state.filledFormFlowSelections = {
        ...cloneDeep(state.filledFormFlowSelections),
        [key]: value,
      };
    },
    setPayloadField: (state, { key, value }) => {
      state.formFlowPayload = {
        ...cloneDeep(state.formFlowPayload),
        [key]: value,
      };
    },
    setSortingParams: (state, sortingParameters) =>
      (state.sortingParams = sortingParameters),
    setContextFormFlowItem: (state, formFlowItem) =>
      (state.contextFormFlowItem = formFlowItem),
    setProcessingAction: (state, processingAction) =>
      (state.processingAction = processingAction),
  },
};
