export const formTemplateElements = [
  { type: 'header', name: 'Header', icon: 'header' },
  { type: 'paragraph', name: 'Paragraph', icon: 'paragraph' },
  { type: 'textarea', name: 'Text area', icon: 'textarea' },
  { type: 'text', name: 'Text field', icon: 'textField' },
  { type: 'radio-group', name: 'Selection button', icon: 'selectButton' },
  { type: 'checkbox', name: 'Checkbox', icon: 'checkbox' },
  { type: 'checkbox-group', name: 'Checkbox group', icon: 'checkboxGroup' },
  { type: 'select', name: 'Dropdown', icon: 'dropdown' },
  { type: 'date', name: 'Date field', icon: 'calendar' },
  { type: 'datetime', name: 'Date/time field', icon: 'clock' },
  { type: 'file', name: 'File upload', icon: 'upload' },
  { type: 'nfc', name: 'NFC', icon: 'nfc' },
];
