export default {
  methods: {
    async postFormTemplate(location, payload) {
      return await $fetch(
        `${this.$baseURL}/api/v2/location/${location.id}/form-flows`,
        {
          method: 'POST',
          body: payload,
        },
      );
    },
    async patchFormTemplate(location, formId, payload) {
      return await $fetch(
        `${this.$baseURL}/api/v2/location/${location.id}/form-flows/${formId}`,
        { method: 'PATCH', body: payload },
      );
    },
    async copyFormTemplate(location, formId, payload) {
      return await $fetch(
        `${this.$baseURL}/api/v2/location/${location.id}/form-flows/${formId}/copy`,
        { method: 'POST', body: payload },
      );
    },
    async getFormTemplate(location, formTemplateId, urlParameters) {
      return await $fetch(
        `${this.$baseURL}/api/v2/location/${location.id}/form-flows/${formTemplateId}`,
        { params: urlParameters },
      );
    },
    async getFormTemplates(location, urlParameters) {
      return await $fetch(
        `${this.$baseURL}/api/v2/location/${location.id}/form-flows?per_page=100`,
        { params: urlParameters },
      );
    },
    async deleteFormTemplate(location, formFlowId) {
      return await $fetch(
        `${this.$baseURL}/api/v2/location/${location.id}/form-flows/${formFlowId}`,
        { method: 'DELETE' },
      );
    },
    async postNewStep(formFlow, payload) {
      return await $fetch(
        `${this.$baseURL}/api/v2/location/${formFlow.location.id}/form-flows/${formFlow.id}/steps`,
        { method: 'POST', body: payload },
      );
    },
    async deleteStep(formFlow, stepId) {
      return await $fetch(
        `${this.$baseURL}/api/v2/location/${formFlow.location.id}/form-flows/${formFlow.id}/steps/${stepId}`,
        { method: 'DELETE' },
      );
    },
    async updateStep(formFlow, payload) {
      return await $fetch(
        `${this.$baseURL}/api/v2/location/${formFlow.location.id}/form-flows/${formFlow.id}/steps/${payload.id}`,
        { method: 'PATCH', body: payload },
      );
    },
    async updateStepOrder(formFlow, payload) {
      return await $fetch(
        `${this.$baseURL}/api/v2/location/${formFlow.location.id}/form-flows/${formFlow.id}/steps/order`,
        { method: 'PATCH', body: payload },
      );
    },
  },
};
