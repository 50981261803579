<script setup lang="ts">
const props = defineProps<{ error: Object }>();

const message = computed(() => String(props.error?.message || ""));
const is404 = computed(
  () => props.error?.statusCode === 404 || message.value?.includes("404")
);

// eslint-disable-next-line unicorn/prevent-abbreviations
const isDev = process.dev;

function handleError() {
  return clearError({ redirect: "/" });
}
</script>

<template>
  <NuxtLayout>
    {{ is404 ? $t("This page could not be found") : $t("An error occurred") }}

    <pre v-if="isDev">{{ error }}</pre>

    <button @click="handleError">
      {{ $t("Go back") }}
    </button>
  </NuxtLayout>
</template>
