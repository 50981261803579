import { useAppContext } from '../composables/useAppContext';

export default defineNuxtPlugin(async (nuxt) => {
  const { appContext, fetchAppContext } = useAppContext();

  try {
    await fetchAppContext();

    updateAppConfig({
      ...appContext.value,
      authBackground: appContext.value.layout.background,
    });
  }
  catch { }

  nuxt.provide('context', () => appContext);
});
