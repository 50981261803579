import revive_payload_client_2fVRf1Aw60 from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@_brx5dwzjirugzwhxybgdew5id4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_MmQIP8MLqA from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@_brx5dwzjirugzwhxybgdew5id4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_dmGs3nfDXy from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@_brx5dwzjirugzwhxybgdew5id4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_TXUAZTDysA from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@_brx5dwzjirugzwhxybgdew5id4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_f3CcZmGftz from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@_brx5dwzjirugzwhxybgdew5id4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_OYzCdB5Cov from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@_brx5dwzjirugzwhxybgdew5id4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_rW6hAIoeY5 from "/app/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.17.2/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_3Duw1s17vU from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@4.17.2_vue@3.4.26/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_A51AowdYyw from "/app/node_modules/.pnpm/@workrate+nuxt-auth@1.0.17_@vuelidate+core@2.0.3_@vuelidate+validators@2.0.4_@vueuse+core@9.1_4kaiwf7ydtk36k56vifkcsgham/node_modules/@workrate/nuxt-auth/dist/runtime/plugin.mjs";
import pwa_icons_vHSbaUtRNt from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@4.17.2_vite@5.2.10_workbox-build@7.1.0_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_QuNcuybjP5 from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@4.17.2_vite@5.2.10_workbox-build@7.1.0_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_6Ph1iQzNHk from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import chunk_reload_client_vkYTnQn05x from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@_brx5dwzjirugzwhxybgdew5id4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_api_oQZWGOKeFT from "/app/plugins/0-api.ts";
import _1_init_DFGoaQHkc5 from "/app/plugins/1-init.ts";
import _2_store_HoxqZeRy9H from "/app/plugins/2-store.js";
import _3_legacy_compat_u7RZg8Xb70 from "/app/plugins/3-legacy-compat.js";
import _4_vue_query_NvXElszJpQ from "/app/plugins/4-vue-query.ts";
import datepicker_client_Aec8sftcls from "/app/plugins/datepicker.client.js";
import echo_client_KeZ3mIweGj from "/app/plugins/echo.client.js";
import eventbus_HoXFXDNDgX from "/app/plugins/eventbus.js";
import hotjar_client_HJG8no80GI from "/app/plugins/hotjar.client.ts";
import trailblazer_client_RDi09qBw86 from "/app/plugins/trailblazer.client.js";
export default [
  revive_payload_client_2fVRf1Aw60,
  unhead_MmQIP8MLqA,
  router_dmGs3nfDXy,
  payload_client_TXUAZTDysA,
  check_outdated_build_client_f3CcZmGftz,
  components_plugin_KR1HBZs4kY,
  prefetch_client_OYzCdB5Cov,
  plugin_rW6hAIoeY5,
  i18n_3Duw1s17vU,
  plugin_A51AowdYyw,
  pwa_icons_vHSbaUtRNt,
  pwa_client_QuNcuybjP5,
  plugin_6Ph1iQzNHk,
  chunk_reload_client_vkYTnQn05x,
  _0_api_oQZWGOKeFT,
  _1_init_DFGoaQHkc5,
  _2_store_HoxqZeRy9H,
  _3_legacy_compat_u7RZg8Xb70,
  _4_vue_query_NvXElszJpQ,
  datepicker_client_Aec8sftcls,
  echo_client_KeZ3mIweGj,
  eventbus_HoXFXDNDgX,
  hotjar_client_HJG8no80GI,
  trailblazer_client_RDi09qBw86
]