/**
 * TODO: Check if this is still needed
 *
 * DISCLAIMER: This is a temporary plugin solution
 * to facilitate a different method of defining a
 * base URL for API requests. It is to be deprecated
 * once we move all API calls over to `useApi`.
 */
export default defineNuxtPlugin((nuxt) => {
  const config = useRuntimeConfig();

  let baseURL;

  if (config?.public.BASE_URL) {
    baseURL = config.public.BASE_URL;
  }
  else if (nuxt.ssrContext) {
    baseURL = nuxt.ssrContext?.event.node.req.headers.host;
  }
  else {
    baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''
      }`;
  }

  nuxt.provide('baseURL', baseURL);
});
