export function capitalize(string_) {
  return string_.charAt(0).toUpperCase() + string_.slice(1);
}

export function toCamelCase(string) {
  const words = string.split('_');

  const capitalized = words.map((word, index) => {
    if (index === 0)
      return word;
    return this.capitalize(word);
  });

  return capitalized.join('');
}

export function toSnakeCase(string) {
  return string.replace(/([\da-z])([A-Z])/g, '$1_$2').toLowerCase();
}
