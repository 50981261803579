/**
 * --------------------------------------------------------------------
 * Create filtering store
 * --------------------------------------------------------------------
 */
import { cloneDeep } from 'lodash-es';

export default {
  namespaced: true,
  state: () => ({
    filteringMenuOpened: false,
    modulesFilters: {},
    triggers: {},
  }),
  getters: {
    filteringMenuOpened(state) {
      return state.filteringMenuOpened;
    },
    modulesFilters(state) {
      return state.modulesFilters;
    },
  },
  mutations: {
    setFilteringMenuOpened: (state, filteringMenuOpened) => {
      state.filteringMenuOpened = filteringMenuOpened;
    },
    clearFilters: (state) => {
      state.modulesFilters = {};
    },
    clearModuleFilters: (state, { module, filterName }) => {
      if (
        state.modulesFilters[module]
        && state.modulesFilters[module][filterName]
      ) {
        const filters = cloneDeep(state.modulesFilters);
        delete filters[module][filterName];
        state.modulesFilters = filters;
      }
    },
    setModuleFilter: (state, { module, field, filterValues }) => {
      state.modulesFilters[module][field] = filterValues;
    },
    addModuleFilter: (state, { module, field, value }) => {
      const filters = state.modulesFilters;

      if (!filters[module])
        filters[module] = {};

      if (!state.modulesFilters[module][field])
        filters[module][field] = [];

      filters[module][field].push(value);

      state.modulesFilters = filters;
    },
    registerFilteringCallback: (state, { module, field, callback }) => {
      if (!state.triggers[module])
        state.triggers[module] = {};

      state.triggers[module][field] = callback;
    },
    triggerModuleFilter: (state, { module, field }) => {
      let callback;
      if (state.triggers[module] && state.triggers[module][field])
        callback = state.triggers[module][field];

      if (!callback)
        return;

      const filterExists
        = state.modulesFilters[module] && state.modulesFilters[module][field];
      callback(
        field,
        filterExists ? state.modulesFilters[module][field] : undefined,
      );
    },
  },
};
