<script>
import { mapGetters } from 'vuex';

import Heading from './components/Heading/index.vue';

import SidebarMixin from '@/components/molecules/Sidebar/mixins/SidebarMixin';
import DisplayMixin from '@/mixins/DisplayMixin';
import ModalControllerMixin from '@/mixins/ModalControllerMixin';
import TicketsManagerMixin from '~/features/tickets/mixins/TicketsManagerMixin';
import {
  loadMore,
  showLoadMore,
  ticketCreationDate,
} from '~/features/tickets/utils/common';

const layoutTypes = {
  mobile: defineAsyncComponent(() => import('./views/Mobile')),
  tablet: defineAsyncComponent(() => import('./views/Tablet')),
  desktop: defineAsyncComponent(() => import('./views/Desktop')),
  small_desktop: defineAsyncComponent(() => import('./views/Desktop')),
};

export default defineNuxtComponent({
  components: {
    Heading,
  },
  mixins: [
    TicketsManagerMixin,
    SidebarMixin,
    ModalControllerMixin,
    DisplayMixin,
  ],
  data() {
    return {
      loadingTickets: false,
    };
  },
  computed: {
    ...mapGetters('TicketsModuleStore', ['fetchedTickets']),
    ticketsDates() {
      return this.sortOnDays();
    },
    computeLayoutType() {
      return 'detailed';
    },
    layoutTypeComponent() {
      return layoutTypes[this.getDisplayMode];
    },
    isOnTicketOverview() {
      return this.$route.path.includes('/ticket');
    },
  },
  watch: {
    $route(newRoute) {
      if (this.$route.query.id && this.isOnTicketOverview)
        this.openTicket(newRoute);
    },
  },
  mounted() {
    this.clearSelectedTickets();
    if (this.$route.query.id)
      this.openTicket(this.$route);
  },
  methods: {
    showLoadMore,
    loadMore,
    ticketCreationDate,

    async loadMoreTickets() {
      if (this.loadingTickets)
        return;

      this.loadingTickets = true;
      await this.loadMore(this.fetchedTickets);
      this.loadingTickets = false;
    },
    async openTicket(route) {
      const ticket = await this.retrieveTicketById(route.query.id);
      if (ticket)
        this.openTicketDetailsModal(ticket.data);
    },
    sortOnDays() {
      const newSet = new Set();
      for (const ticket of this.fetchedTickets.data)
        newSet.add(this.ticketCreationDate(ticket));

      return newSet;
    },
    openTicketDetailsModal(ticket) {
      this.$store.commit('CreateTicketStore/clearTicketStore');
      this.setModalType('ticketDetailsModal');
      this.setModalData(ticket);
      this.setModalOpened(true);
    },
  },
});
</script>

<template>
  <Heading />
  <div>
    <component
      :is="layoutTypeComponent"
      :layout-type="computeLayoutType"
      :tickets-dates="ticketsDates"
      :loading-tickets="loadingTickets"
      @load-more-tickets="loadMoreTickets"
    />
  </div>
</template>
