import { DialogProvider, ToastProvider } from '@workrate/trailblazer-ui';

/**
 * @deprecated
 * We're gradually transitioning towards Radix & Sentinel UI.
 * This plugin should be removed once it's no longer needed.
 */
export default defineNuxtPlugin(async (nuxt) => {
  await import('@workrate/trailblazer-ui');

  nuxt.provide('dialog', DialogProvider.getInstance());
  nuxt.provide('toasty', ToastProvider.getInstance());
});
