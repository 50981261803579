/**
 * --------------------------------------------------------------------
 * Create new modal store
 * --------------------------------------------------------------------
 */

export default {
  namespaced: true,
  state: () => ({
    primaryActionButtonDisabled: false,
    primaryActionButtonLabel: 'Primary action',
  }),
  getters: {
    primaryActionButtonDisabled(state) {
      return state.primaryActionButtonDisabled;
    },
    primaryActionButtonLabel(state) {
      return state.primaryActionButtonLabel;
    },
  },
  mutations: {
    primaryActionButtonDisabled: (state, value) => {
      state.primaryActionButtonDisabled = value;
    },
    primaryActionButtonLabel: (state, value) => {
      state.primaryActionButtonLabel = value;
    },
    resetPrimaryActionButtonToDefault: (state) => {
      state.primaryActionButtonDisabled = false;
      state.primaryActionButtonLabel = 'Primary action';
    },
  },
};
