import {
  ANNOUNCEMENT_MODAL,
  DIRECT_SIGN_IN_MODAL,
  REGISTRATION_MODAL,
  SIGN_IN_MODAL,
} from '~/features/registrations/components/constants';
import {
  REGISTERED_SHIPMENT_DETAILS_MODAL,
  SHIPMENT_ANNOUNCE_CREATE_MODAL,
  SHIPMENT_RECEIVE_CREATE_MODAL,
} from '~/features/shipment/constants';

export default defineNuxtComponent({
  name: 'ModalControllerMixin',
  methods: {
    resetModal() {
      this.$store.commit('ModalStore/resetData');
      this.$store.commit('CreateNewModal/resetPrimaryActionButtonToDefault');
    },
    setModalAbilities(modalAbilities) {
      this.$store.commit('ModalStore/setModalAbilities', modalAbilities);
    },
    setModalData(modalData) {
      this.$store.commit('ModalStore/setModalData', modalData);
    },
    setModalTitle(modalTitle) {
      this.$store.commit('ModalStore/setTitle', modalTitle);
    },
    setApplyContentWidth(value) {
      this.$store.commit('ModalStore/setApplyContentWidth', value);
    },
    setModalType(modalType) {
      this.resetModal();
      this.setModalLayout(this.establishModalLayout(modalType));
      this.$store.commit('ModalStore/setModalType', modalType);
    },
    setModalOpened(modalOpened) {
      if (!modalOpened) {
        this.$router.push({ path: this.$route.path, query: {} });
        this.resetModal();
      }
      this.$store.commit('ModalStore/setModalOpened', modalOpened);
    },
    setModalLayout(modalLayout) {
      this.$store.commit('ModalStore/setModalLayout', modalLayout);
    },
    establishModalLayout(modalType) {
      switch (modalType) {
        case 'ticketDetailsModal': {
          return 'ticketDetailsModal';
        }
        case 'formDetailsModal': {
          return 'formDetailsModal';
        }
        case 'registrationAnnounce': {
          return 'registrationAnnounce';
        }
        case 'registrationEdit': {
          return 'registrationEdit';
        }
        case ANNOUNCEMENT_MODAL: {
          return ANNOUNCEMENT_MODAL;
        }
        case SIGN_IN_MODAL: {
          return SIGN_IN_MODAL;
        }
        case DIRECT_SIGN_IN_MODAL: {
          return DIRECT_SIGN_IN_MODAL;
        }
        case REGISTRATION_MODAL: {
          return REGISTRATION_MODAL;
        }
        case SHIPMENT_ANNOUNCE_CREATE_MODAL: {
          return SHIPMENT_ANNOUNCE_CREATE_MODAL;
        }
        case SHIPMENT_RECEIVE_CREATE_MODAL: {
          return SHIPMENT_RECEIVE_CREATE_MODAL;
        }
        case REGISTERED_SHIPMENT_DETAILS_MODAL: {
          return REGISTERED_SHIPMENT_DETAILS_MODAL;
        }
        default: {
          return 'modal';
        }
      }
    },
  },
});
