/**
 * --------------------------------------------------------------------
 * Sidebar store
 * --------------------------------------------------------------------
 */

export default {
  namespaced: true,
  state: () => ({
    firstLevelSidebarIsCollapsed: true,
    secondLevelSidebarIsCollapsed: true,
    selectedNavigationItem: {},
  }),
  getters: {
    firstLevelSidebarIsCollapsed(state) {
      return state.firstLevelSidebarIsCollapsed;
    },
    secondLevelSidebarIsCollapsed(state) {
      return state.secondLevelSidebarIsCollapsed;
    },
    selectedNavigationItem(state) {
      return state.selectedNavigationItem;
    },
  },
  mutations: {
    setFirstLevelSidebarIsCollapsed: (state, firstLevelSidebarIsCollapsed) =>
      (state.firstLevelSidebarIsCollapsed = firstLevelSidebarIsCollapsed),
    setSecondLevelSidebarIsCollapsed: (state, secondLevelSidebarIsCollapsed) =>
      (state.secondLevelSidebarIsCollapsed = secondLevelSidebarIsCollapsed),
    setSelectedNavigationItem: (state, selectedNavigationItem) =>
      (state.selectedNavigationItem = selectedNavigationItem),
  },
};
