export default defineNuxtComponent({
  name: 'ConfirmationModalControllerMixin',
  methods: {
    setConfirmationModalOpened(opened) {
      this.$store.commit(
        'ConfirmationModalStore/setConfirmationModalOpened',
        opened,
      );
    },
    setConfirmationModalTitle(title) {
      this.$store.commit(
        'ConfirmationModalStore/setConfirmationModalTitle',
        title,
      );
    },
    setConfirmationModalMessage(message) {
      this.$store.commit(
        'ConfirmationModalStore/setConfirmationModalMessage',
        message,
      );
    },
    setConfirmationModalData(data) {
      this.$store.commit(
        'ConfirmationModalStore/setConfirmationModalData',
        data,
      );
    },
    setConfirmationModalType(type) {
      this.$store.commit(
        'ConfirmationModalStore/setConfirmationModalType',
        type,
      );
    },
    setConfirmationModalCallbacks(callbacksList) {
      this.$store.commit(
        'ConfirmationModalStore/setConfirmationModalCallbacks',
        callbacksList,
      );
    },
    setConfirmationExtraButtons(extraButtons) {
      this.$store.commit(
        'ConfirmationModalStore/setConfirmationExtraButtons',
        extraButtons,
      );
    },
  },
});
