import { mapGetters } from 'vuex';

export default defineNuxtComponent({
  name: 'FilteringControllerMixin',
  computed: {
    ...mapGetters('FilteringStore', ['modulesFilters', 'filteringMenuOpened']),
    isFilteringMenuOpened() {
      return this.filteringMenuOpened;
    },
  },
  methods: {
    hasAvailableFilters(module) {
      return (
        this.modulesFilters[module]
          && Object.keys(this.modulesFilters[module]).length > 0
      );
    },
    toggleFilteringMenu() {
      const filteringOpened = !this.filteringMenuOpened;
      this.$store.commit(
        'FilteringStore/setFilteringMenuOpened',
        filteringOpened,
      );
    },
    clearFilter() {
      this.$store.commit('FilteringStore/clearFilters');
    },
    clearModuleFilter(module, filterName) {
      this.$store.commit('FilteringStore/clearModuleFilters', {
        module,
        filterName,
      });
    },
    addModuleFilter(module, field, value) {
      this.$store.commit('FilteringStore/addModuleFilter', {
        module,
        field,
        value,
      });
    },
    setModuleFilter(module, field, filterValues) {
      if (filterValues === undefined) {
        this.clearModuleFilter(module, field);
      }
      else {
        this.$store.commit('FilteringStore/setModuleFilter', {
          module,
          field,
          filterValues,
        });
      }
    },
    removeModuleFilterValue(module, field, value) {
      const filterValues = this.modulesFilters[module][field];
      const index = filterValues.findIndex(value_ => value_.id === value.id);
      if (index === -1)
        return;

      filterValues.splice(index, 1);
      if (filterValues.length === 0) {
        this.clearModuleFilter(module, field);
      }
      else {
        this.$store.commit('FilteringStore/setModuleFilter', {
          module,
          field,
          filterValues,
        });
      }
    },
    registerFilteringCallback(module, field, callback) {
      this.$store.commit('FilteringStore/registerFilteringCallback', {
        module,
        field,
        callback,
      });
    },
    triggerModuleFilter(module, field) {
      this.$store.commit('FilteringStore/triggerModuleFilter', {
        module,
        field,
      });
    },
    getModuleFilterSelections(module, field) {
      if (this.modulesFilters[module])
        return this.modulesFilters[module][field];
    },
    isModuleRegistered(module, name) {
      return this.modulesFilters[module] && this.modulesFilters[module][name];
    },
    filteringChanged(module, name, selectedOptions) {
      this.clearModuleFilter(module, name);

      if (!selectedOptions) {
        this.triggerModuleFilter(module, name);
        return;
      }

      if (this.isModuleRegistered(module, name)) {
        for (const option of selectedOptions) {
          const isRegistered = this.modulesFilters[module][name].find(
            value => value.id === option.id,
          );

          if (isRegistered)
            this.removeModuleFilterValue(module, name, option);

          else
            this.addModuleFilter(module, name, option);
        }
      }
      else {
        for (const option of selectedOptions)
          this.addModuleFilter(module, name, option);
      }

      this.triggerModuleFilter(module, name);
    },
  },
});
