<script setup lang="ts">
const props = defineProps<{
  value?: unknown
}>();

const [isEditing, toggleEditing] = useToggle(false);

const internalValue = ref(props.value);

watch(isEditing, () => internalValue.value = props.value);

function setValue(newValue: unknown) {
  internalValue.value = newValue;
}
</script>

<template>
  <div v-if="!isEditing" :toggle="toggleEditing" class="group inline-flex cursor-pointer items-center gap-2" @click="toggleEditing(true)">
    <slot />

    <span class="icon-[ph--pencil] text-primary opacity-0 group-hover:opacity-100" />
  </div>
  <div v-else>
    <slot :toggle="toggleEditing" :value="internalValue" :set-value="setValue" name="edit" />
  </div>
</template>
