/**
 * --------------------------------------------------------------------
 * Create form flow store
 * --------------------------------------------------------------------
 */

export default {
  namespaced: true,
  state: () => ({
    contextFormFlow: {},
    fetchFormFlowsTrigger: false,
  }),
  getters: {
    contextFormFlow(state) {
      return state.contextFormFlow;
    },
    fetchFormFlowsTrigger(state) {
      return state.fetchFormFlowsTrigger;
    },
  },
  mutations: {
    contextFormFlow: (state, value) => {
      state.contextFormFlow = value;
    },
    fetchFormFlowsTrigger: state =>
      (state.fetchFormFlowsTrigger = !state.fetchFormFlowsTrigger),
  },
};
