import { mapGetters } from 'vuex';

import TicketsApiMixin from './TicketsApiMixin';
import { toCamelCase, toSnakeCase } from '@/utilities/string-utilities';
import LocationsControllerMixin from '~/mixins/Locations/LocationsControllerMixin';

const DEFAULT_SORTING_PARAMETER = '-id';

export default defineNuxtComponent({
  mixins: [TicketsApiMixin, LocationsControllerMixin],
  data() {
    return {
      editingComment: undefined,
      editCommentMessage: '',
      desc: true,
    };
  },
  computed: {
    ...mapGetters('LocationsStore', [
      'groupsAndLocations',
      'locations',
      'groups',
    ]),
    ...mapGetters('TicketsModuleStore', [
      'fetchedTickets',
      'priorities',
      'users',
      'groups',
      'types',
      'statuses',
      'escalations',
      'ticketPayload',
      'sortingParams',
      'processingAction',
      'validations',
      'selectedTickets',
      'payloadSnapshot',
    ]),
    user() {
      return useAuth().user;
    },
  },
  watch: {
    ticketPayload(newValues) {
      let validForm = true;
      if (this.validations && this.validations.required) {
        for (const validation of this.validations.required) {
          if (!newValues[validation] || newValues[validation] === '')
            validForm = false;
        }
      }

      this.$store.commit(
        'TicketsModuleStore/setValidationsValidForm',
        validForm,
      );
    },
  },
  methods: {
    toSnakeCase,
    toCamelCase,
    setDefaultSortingParams(status) {
      this.setSortingParams({
        include_abilities: true,
        sort: DEFAULT_SORTING_PARAMETER,
        status_id: status,
        per_page: 25,
      });
    },
    reorderAssigneeList(assignees, user) {
      return [
        user,
        ...assignees.filter(assignee => assignee.name !== user.name),
      ];
    },
    async getTicketPriorities() {
      if (this.priorities.length === 0) {
        const response = await this.getPriorities();
        this.$store.commit('TicketsModuleStore/setPriorities', response.data);
        return response.data;
      }

      return this.priorities;
    },
    async getTicketUsers(forceUpdate) {
      if (this.users.length === 0 || forceUpdate) {
        try {
          const response = await this.getUsers();
          const reorderedResponseList = this.reorderAssigneeList(
            response.data,
            this.user,
          );
          this.$store.commit(
            'TicketsModuleStore/setUsers',
            reorderedResponseList,
          );
          return reorderedResponseList;
        }
        catch {
          this.$toasty.$error({
            title: this.__('Something went wrong'),
            message: this.__('Failed to receive users'),
            autoDismiss: 5000,
            callback: () => {},
          });
        }
      }
      else {
        return this.reorderAssigneeList(this.users, this.user);
      }
    },
    async getLocations() {
      if (this.groupsAndLocations.length === 0) {
        const response = await this.getGroupsLocations();
        this.$store.commit(
          'LocationsStore/setGroupsAndLocations',
          response.data,
        );
        return response.data;
      }
      else {
        for (const element of this.groupsAndLocations) {
          if (
            !this.locations.some(
              location => location.id === element.location.id,
            )
          )
            this.$store.commit('LocationsStore/addLocation', element.location);
        }

        return this.locations;
      }
    },
    async getTicketGroups() {
      if (this.groups.length === 0) {
        const response = await this.getGroups();
        this.$store.commit('TicketsModuleStore/setGroups', response.data);
        return response.data;
      }
      else {
        return this.groups;
      }
    },
    async getLocationGroups(locationId, force) {
      if (this.groups.length === 0 || force) {
        const response = await this.getTicketGroupsForLocation(locationId);
        this.$store.commit('TicketsModuleStore/setGroups', response.data);
        return response.data;
      }
      else {
        return this.groups;
      }
    },
    async uploadFile(file) {
      try {
        if (file.size === 0)
          throw new Error('error');

        return await this.uploadFileToTicket(file);
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__(`Failed to attach file ${file.name}`),
          autoDismiss: 5000,
        });
      }
    },
    async attachFile(ticketId, file) {
      try {
        if (file.size === 0)
          throw new Error('error');

        return await this.attachFileToTicket(ticketId, file);
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__(`Failed to attach file ${file.name}`),
          autoDismiss: 5000,
        });
      }
    },
    async updateTicket(ticketId) {
      return await this.putTicket(ticketId, this.ticketPayload);
    },
    async updateTicketStatus(ticketId, statusId) {
      return await this.putTicketStatus(ticketId, statusId);
    },
    async submitTicket() {
      return await this.postTicket(this.ticketPayload);
    },
    async retrieveTickets() {
      if (this.getSelectedLocation()) {
        try {
          this.setProcessingAction(true);
          const response = await this.getTickets(this.sortingParams);
          this.setTickets(response);
          this.setValidations(response.meta.validation.post.required);
          this.setProcessingAction(false);
          return response.data;
        }
        catch {
          this.$toasty.$error({
            title: this.__('Something went wrong!'),
            message: this.__(`Failed to retrieve tickets`),
            autoDismiss: 5000,
          });
        }
      }
    },
    async retrieveNextTickets() {
      try {
        const oldData = this.fetchedTickets.data;

        if (this.fetchedTickets.links?.next) {
          const response = await this.getByUrl(this.fetchedTickets.links.next);
          response.data = [...oldData, ...response.data];
          this.setTickets(response);
          return response.data;
        }
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__(`Failed to load more tickets`),
          autoDismiss: 5000,
        });
      }
    },
    async retrieveTicketStatuses() {
      if (this.statuses.length === 0) {
        const response = await this.getStatuses();
        this.$store.commit('TicketsModuleStore/setStatuses', response.data);
        return response.data;
      }

      return this.statuses;
    },
    clearTicketTypes() {
      this.$store.commit('TicketsModuleStore/setTypes', []);
    },
    async getAssigneesForGroup(selectedGroup, ignoreCache) {
      if (!selectedGroup)
        return;

      if (this.assignees.length === 0 || ignoreCache) {
        const response = await this.getAssignees(selectedGroup.id);

        const authenticatedUser = response.data.find(
          assignee => assignee.name === this.user.name,
        );
        if (authenticatedUser) {
          const reorderedAssigneeList = this.reorderAssigneeList(
            response.data,
            authenticatedUser,
          );

          this.$store.commit(
            'TicketsModuleStore/setAssignees',
            reorderedAssigneeList,
          );
          return reorderedAssigneeList;
        }
        this.$store.commit('TicketsModuleStore/setAssignees', response.data);
        return response.data;
      }

      const authenticatedUser = this.assignees.find(
        assignee => assignee.name === this.user.name,
      );
      if (authenticatedUser) {
        const reorderedAssigneeList = this.reorderAssigneeList(
          this.assignees,
          authenticatedUser,
        );
        this.$store.commit(
          'TicketsModuleStore/setAssignees',
          reorderedAssigneeList,
        );
        return reorderedAssigneeList;
      }

      return this.assignees;
    },
    async getTicketEscalations(locationId, ignoreCache) {
      if (this.escalations.length === 0 || ignoreCache) {
        const response = await this.getEscalations(locationId);
        this.$store.commit('TicketsModuleStore/setEscalations', response.data);
        return response.data;
      }

      return this.escalations;
    },
    async getTicketTypes(locationId, groupId) {
      const response = await this.getTypes(locationId, groupId);
      this.$store.commit('TicketsModuleStore/setTypes', response.data);
      return response.data;
    },
    async removeTicket(ticketId) {
      try {
        return await this.deleteTicket(ticketId);
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__(`Failed to delete ticket.`),
          autoDismiss: 5000,
        });
        return false;
      }
    },
    ticketFieldChanged() {
      return (
        JSON.stringify(this.ticketPayload)
        !== JSON.stringify(this.payloadSnapshot)
      );
    },
    setSortingParams(parameters) {
      this.$store.commit('TicketsModuleStore/setSortingParams', parameters);
    },
    takePayloadSnapshot() {
      this.$store.commit('TicketsModuleStore/takePayloadSnapshot');
    },
    setPayloadSnapshot(payload) {
      this.$store.commit('TicketsModuleStore/setPayloadSnapshot', payload);
    },
    setProcessingAction(processing) {
      this.$store.commit('TicketsModuleStore/setProcessingAction', processing);
    },
    setTicketErrors(errors) {
      this.$store.commit('TicketsModuleStore/setTicketErrorTrigger', errors, {
        root: true,
      });
    },
    setValidations(validations) {
      this.$store.commit('TicketsModuleStore/setValidations', validations);
    },
    setTickets(data) {
      this.$store.commit('TicketsModuleStore/fetchedTickets', data);
    },
    async retrieveTicketById(ticketId) {
      try {
        return await this.getTicketById(ticketId);
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__(`Failed to fetch ticket with id ${ticketId}`),
          autoDismiss: 5000,
        });
      }
    },
    isFieldRequired(field) {
      if (this.validations && this.validations.required)
        return this.validations.required.includes(field);

      return false;
    },
    validateData(data) {
      let validForm = true;
      if (this.validations && this.validations.required) {
        for (const validation of this.validations.required) {
          if (!data[validation] || data[validation] === '')
            validForm = false;
        }
      }

      this.$store.commit(
        'TicketsModuleStore/setValidationsValidForm',
        validForm,
      );
    },
    loadPayloadFromData(data) {
      const payload = {};

      payload.title = data.title;
      payload.description = data.description;
      payload.action = data.action;
      payload.ticket_number = data.ticket_number;
      payload.ticket_date = data.ticket_date;
      payload.deadline_date = data.deadline_date;
      payload.location_id = data.location?.id;
      payload.group_id = data.group?.id;
      payload.ticket_type_id = data.ticket_type?.id;
      payload.ticket_category_id = data.ticket_category?.id;
      payload.user_id = data.user?.id;
      payload.assignee_id = data.assignee?.id;
      payload.status_id = data.status?.id;
      payload.priority_id = data.priority?.id;

      if (data.files && data.files.length > 0)
        payload.file_ids = data.files.map(x => x.id);

      this.$store.commit('TicketsModuleStore/setPayload', payload, {
        root: true,
      });
    },
    isTicketSelected(item) {
      return (
        this.selectedTickets.some(element => element.id === item.id) !== false
      );
    },
    onTicketCheckboxClick(item) {
      const foundTicket = this.selectedTickets.find(
        element => element.id === item.id,
      );

      if (foundTicket) {
        const ticketIndex = this.selectedTickets.indexOf(foundTicket);
        this.$store.commit(
          'TicketsModuleStore/removeSelectedTickets',
          ticketIndex,
        );
      }
      else {
        this.$store.commit('TicketsModuleStore/addSelectedTickets', item);
      }
    },
    clearSelectedTickets() {
      this.$store.commit('TicketsModuleStore/setSelectedTickets', []);
    },
  },
});
