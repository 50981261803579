import dayjs from 'dayjs';
import { isArray } from 'lodash-es';
import { mapGetters } from 'vuex';

import FormFlowApiMixin from './FormFlowApiMixin';

export default defineNuxtComponent({
  name: 'FormFlowManagerMixin',
  mixins: [FormFlowApiMixin],
  data() {
    return {
      currentStepIndex: 0,
    };
  },
  computed: {
    ...mapGetters('CreateFormFlowStore', ['contextFormFlow']),
    ...mapGetters('FormFlowsModuleStore', [
      'selectedFormFlowLocationForm',
      'selectedStep',
      'selectedFormFlow',
      'reporters',
      'formFlowPayload',
      'validations',
      'contextFormFlowItem',
      'filledFormFlowSelections',
      'filledSelectionsSnapshot',
      'sortingParams',
      'formFlowSubmissions',
      'processingAction',
    ]),
  },
  watch: {
    formFlowPayload(newValues) {
      let validForm = true;
      if (this.validations && this.validations.required) {
        for (const validation of this.validations.required) {
          if (!newValues[validation]) {
            validForm = false;
            break;
          }
        }
      }

      this.$store.commit(
        'FormFlowsModuleStore/setValidationsValidForm',
        validForm,
      );
    },
  },
  methods: {
    downloadFile(formFlow, item, as) {
      const link = document.createElement('a');
      link.setAttribute(
        'href',
        `/api/v2/location/${formFlow.location.id}/form-flows/${formFlow.id}/items/${item.id}/export-${as}`,
      );
      link.setAttribute('download', 'download');
      link.setAttribute('target', '_blank');

      document.body.append(link);
      link.click();
      link.remove();
    },
    setDefaultSortingParams() {
      this.setSortingParams({
        per_page: 25,
      });
    },
    computeUpdatedDate() {
      let finalDate = dayjs(new Date(0)).format('YYYY-mmmm-dd, HH:mm');
      if (this.formFlowSubmissions && this.formFlowSubmissions.submissions) {
        for (const submission of this.formFlowSubmissions.submissions) {
          if (submission.updated_at > finalDate)
            finalDate = submission.updated_at;
        }
      }

      return finalDate;
    },
    setProcessingAction(processingAction) {
      this.$store.commit(
        'FormFlowsModuleStore/setProcessingAction',
        processingAction,
      );
    },
    takeFilledFormFlowSnapshot() {
      this.$store.commit('FormFlowsModuleStore/takeFilledFormFlowSnapshot');
    },
    formFlowItemChanged() {
      return (
        JSON.stringify(this.filledFormFlowSelections)
        !== JSON.stringify(this.filledSelectionsSnapshot)
      );
    },
    setSelectedFormFlowLocationForm(form) {
      return this.$store.commit(
        'FormFlowsModuleStore/setSelectedFormFlowLocationForm',
        form,
        { root: true },
      );
    },
    setSortingParams(sortParameters) {
      this.$store.commit(
        'FormFlowsModuleStore/setSortingParams',
        sortParameters,
      );
    },
    setFormFlowSteps(steps) {
      this.$store.commit('FormFlowsModuleStore/setFormFlowSteps', steps);
    },
    setFormFlowSubmissions(submissions) {
      this.$store.commit(
        'FormFlowsModuleStore/setFormFlowSubmissions',
        submissions,
      );
    },
    async retrieveLocationForms(location, status) {
      const response = await this.fetchForms(location, {
        status,
        per_page: 999,
        sort: 'name',
      });
      this.setFetchedLocationForms(response.data);
    },
    async retrieveGroupReporters(groupId) {
      if (this.reporters.length === 0) {
        const response = await this.fetchGroupReporters(groupId);
        this.$store.commit('FormFlowsModuleStore/setReporters', response.data);
        return response.data;
      }

      return this.reporters;
    },
    async retrieveFormFlowSteps(formFlow) {
      if (formFlow && formFlow.id) {
        const response = await this.fetchFormFlowSteps(
          formFlow,
          this.sortingParams,
        );

        this.$store.commit(
          'FormFlowsModuleStore/setFormFlowSteps',
          response.data,
        );
        return response.data;
      }
    },
    async fetchFormFlowItems() {
      if (!this.selectedFormFlowLocationForm)
        return;

      await this.retrieveFormFlows();
    },
    async retrieveFormFlowSubmissions(formFlow) {
      const response = await this.fetchFormFlowItemWithSubmissions(
        this.selectedFormFlowLocationForm.location.id,
        this.selectedFormFlowLocationForm.id,
        formFlow.id,
      );
      this.$store.commit(
        'FormFlowsModuleStore/setFormFlowSubmissions',
        response.data,
      );
      return response.data;
    },
    async retrieveFormFlowFilledSteps(formFlow) {
      const response = await this.fetchFormFlowFilledSteps(
        formFlow,
        this.selectedFormFlowLocation,
        this.selectedFormFlowLocationForm,
      );

      this.$store.commit(
        'FormFlowsModuleStore/setFormFlowSteps',
        response.data,
      );
      return response.data;
    },
    async retrieveFormFlows() {
      if (
        !this.selectedFormFlowLocationForm
        || !this.selectedFormFlowLocationForm.id
      )
        return;

      try {
        const response = await this.fetchFormFlows(
          this.selectedFormFlowLocationForm.location,
          this.selectedFormFlowLocationForm,
          {
            ...this.sortingParams,
            include_abilities: true,
          },
        );

        this.setFormFlows(response);
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__('Failed to fetch form flows'),
          autoDismiss: 5000,
        });
      }
    },
    async retrieveNextFormFlows() {
      if (this.processingAction)
        return;

      try {
        this.setProcessingAction(true);

        const oldData = this.formFlows.data;

        if (this.formFlows?.links?.next) {
          const response = await this.getByUrl(this.formFlows.links.next);
          response.data = [...oldData, ...response.data];
          this.setFormFlows(response);
          this.setProcessingAction(false);
          return response.data;
        }
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong!'),
          message: this.__('Failed to load more form flows'),
          autoDismiss: 5000,
        });
      }
    },
    setFetchedLocationForms(forms) {
      this.$store.commit('FormFlowsModuleStore/setFetchedLocationForms', forms);
    },
    setFormFlows(formFlows) {
      this.$store.commit('FormFlowsModuleStore/setFormFlows', formFlows);
    },
    setSelectedFormFlowItems(formFlowItems) {
      this.$store.commit(
        'FormFlowsModuleStore/setSelectedFormFlowItems',
        formFlowItems,
      );
    },
    async updateFormSubmission(formFlowItem, submissionId, closeItem) {
      const payload = this.computeStepPayload();
      if (closeItem !== undefined)
        payload.item_state = closeItem ? 'closed' : 'open';

      return await this.updateFormFlowStepSubmission(
        this.selectedFormFlowLocationForm.location.id,
        this.selectedFormFlowLocationForm.id,
        this.selectedStep.id,
        formFlowItem.id,
        submissionId,
        payload,
      );
    },
    async createFormSubmission(formFlowItem, closeItem) {
      const payload = this.computeStepPayload();
      if (closeItem !== undefined)
        payload.item_state = closeItem ? 'closed' : 'open';

      return await this.createNewFormFlowStepSubmission(
        this.selectedFormFlowLocationForm.location.id,
        this.selectedFormFlowLocationForm.id,
        this.selectedStep.id,
        formFlowItem.id,
        payload,
      );
    },
    async createNewFormFlowItem() {
      return await this.createFormFlowItem(
        this.selectedFormFlowLocationForm.location.id,
        this.selectedFormFlowLocationForm.id,
      );
    },
    async uploadFileToForm(file) {
      return await this.attachFileToForm(
        this.selectedFormFlowLocationForm,
        this.selectedFormFlowLocationForm.location,
        file,
      );
    },
    computeStepPayload() {
      const payload = {};

      for (const element of this.selectedStep.elements) {
        const selection = this.filledFormFlowSelections[element.name];
        // Temporary logic to adapt every existing entry to previous WS2 logic
        if (selection) {
          payload[element.name]
            = element.type === 'checkbox-group'
              ? selection.value.map(item => item.value)
              : selection.value;
        }
      }

      return payload;
    },
    getElementData(responseElement) {
      // Checking if the element contains a list of options (checkbox-group, dropdown, radio button)
      if (responseElement.options) {
        const responseElementValues
          = isArray(responseElement.value)
          && responseElement.value.some(option => option.selected)
            ? responseElement.value.filter(option => option.selected)
            : responseElement.options.filter(option => option.selected);

        return {
          question: responseElement.label,
          name: responseElement.name,
          value: responseElementValues,
          answer:
            responseElementValues.length > 0
              ? responseElementValues.map(option => option.label).join(', ')
              : 'No Value',
          type: responseElement.type,
        };
      }
      else {
        if (responseElement.type === 'file') {
          return {
            question: responseElement.label,
            name: responseElement.name,
            value: responseElement.value[0],
            answer:
              responseElement.value.length > 0
                ? responseElement.value[0].filename
                : 'No Value',
            type: responseElement.type,
          };
        }
        if (responseElement.value) {
          return {
            question: responseElement.label,
            name: responseElement.name,
            answer:
              responseElement.type === 'checkbox'
                ? 'Yes'
                : responseElement.value,
            type: responseElement.type,
          };
        }
        return {
          question: responseElement.label,
          name: responseElement.name,
          answer: responseElement.type === 'checkbox' ? 'No' : '',
        };
      }
    },
    stepSubmissions(step, submissions) {
      const filteredSubmissions = submissions.filter(
        submission => submission.step_id === step.id,
      );

      if (filteredSubmissions.length === 0)
        return [];

      const filteredSubmissionsData = filteredSubmissions.map(
        item => item.submission.form_data,
      );
      let submissionData = [];

      for (const element of step.elements) {
        if (element.type === 'header') {
          submissionData = [
            ...submissionData,
            {
              question: element.label,
              name: element.name,
              type: element.type,
              subtype: element.subtype,
            },
          ];
        }
        else if (element.type === 'paragraph') {
          submissionData = [
            ...submissionData,
            {
              question: element.label,
              name: element.name,
              text: element.text,
              type: element.type,
            },
          ];
        }
        else {
          const submission = filteredSubmissionsData.find(data =>
            Object.keys(data).find(item => item === element.name),
          )?.[element.name];

          if (submission) {
            submissionData = [
              ...submissionData,
              this.getElementData(submission),
            ];
          }
        }
      }

      return submissionData;
    },
  },
});
