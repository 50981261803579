export default defineNuxtComponent({
  name: 'LocationsApiMixin',
  methods: {
    async getAllLocations() {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/locations?embed=groups&per_page=999`, {
        baseURL: this.$baseURL,
        headers,
      });
    },
    async getGroupsByLocationId(locationId) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/location/${locationId}/groups`, {
        baseURL: this.$baseURL,
        headers,
      });
    },
  },
});
