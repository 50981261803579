<script>
export default defineNuxtComponent({
  name: 'TextInput',
  emits: ['input'],
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    border: {
      type: Boolean,
      required: false,
      default: true,
    },
    extraClasses: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      textValue: this.value,
    };
  },
  methods: {
    onInput() {
      this.$emit('input', this.textValue);
    },
  },
});
</script>

<template>
  <trailblazer-textfield
    v-model="textValue"
    expand
    class="p-1"
    :disabled="disabled"
    :placeholder="placeholder"
    :label="label"
    :required="required"
    @input="onInput"
  />
</template>
