export const REGISTRATION_MODULE = 'registrations';

export const ANNOUNCEMENT = 'Announced';
export const SIGNED_IN = 'signed-in';
export const SIGNED_OUT = 'signed-out';

export const DIRECT_SIGN_IN_MODAL = 'directSignIn';
export const ANNOUNCEMENT_MODAL = 'announcementDetailsModal';
export const SIGN_IN_MODAL = 'signInVisitor';

export const REGISTRATION_MODAL = 'registrationDetailsModal';

export const ANNOUNCEMENT_PAGE = '/access-request/announced';
export const SIGNED_IN_PAGE = '/access-request/signedin';
export const SIGNED_OUT_PAGE = '/access-request/signedout';
