/* eslint-disable node/prefer-global/process */
import Hotjar from '@hotjar/browser';

export default defineNuxtPlugin(() => {
  const options = useRuntimeConfig().public.hotjar;
  const isDev = process.env.NODE_ENV !== 'production';

  if (isDev)
    // eslint-disable-next-line no-console
    console.log('%c[hotjar] You\'re in development mode', 'color: #fff; background: #f00; padding: 2px 4px; border-radius: 4px; font-weight: bold;');

  Hotjar.init(options.hotjarId, options.scriptVersion, {
    debug: isDev,
  });
});
