export const ANNOUNCED = 'Announced';
export const RECEIVED = 'Received';
export const DELIVERED = 'Delivered';

export const SHIPMENT_ANNOUNCE_CREATE_MODAL = 'shipmentAnnounceCreate';
export const SHIPMENT_RECEIVE_CREATE_MODAL = 'shipmentReceiveCreate';
export const REGISTERED_SHIPMENT_DETAILS_MODAL = 'registeredShipmentDetails';
export const SHIPMENT_DELIVERY_CLOSE_MODAL = 'shipmentDelivery';

export const SHIPMENT_INBOUND_STATUS = 'inbound';
export const SHIPMENT_OUTBOUND_STATUS = 'outbound';
