import ToastUtilities from '~/utilities/toast-utilities';

export default defineNuxtComponent({
  name: 'ToastManagerMixin',
  methods: {
    callToast(
      toastType,
      toastTitle,
      toastMessage,
      toastLinkMessage,
      onMessageClickCallback,
    ) {
      const toastMessageClass
        = toastType === ToastUtilities.TOAST_SUCCESS
          ? 'text-primary truncate max-w-xs'
          : 'truncate max-w-xs';
      const toastLinkClass
        = toastType === ToastUtilities.TOAST_ERROR
          ? 'text-primary truncate max-w-xs'
          : 'truncate max-w-xs';
      const link = toastLinkMessage && toastMessage ? ' | ' : '';

      this.$eventBus.$emit('toast:show', {
        type: toastType,
        title: this.__(toastTitle),
        message: `<div class="${toastMessageClass}">${this.__(
          toastMessage,
        )}</div>${link}<div class="${toastLinkClass}">${this.__(
          toastLinkMessage,
        )}</div>`,
        autoDismiss: 5000,
        callback: onMessageClickCallback,
      });
    },
  },
});
