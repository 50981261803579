/**
 * --------------------------------------------------------------------
 * Theme store
 * --------------------------------------------------------------------
 */

export default {
  namespaced: true,
  state: () => ({
    color: '',
    borderColor: '',
  }),
  getters: {
    color: state => state.color,
  },
  mutations: {
    /**
     * Set translations
     * @param state
     * @param color
     * @return {*}
     */
    setColor: (state, color) => (state.color = color),
  },
};
