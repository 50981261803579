<script>
import { IconNfc } from '@tabler/icons-vue';

const icons = {
  arrowLeft: defineAsyncComponent(() => import('./icons/arrow-left')),
  arrowRight: defineAsyncComponent(() => import('./icons/arrow-right')),
  badge: defineAsyncComponent(() => import('./icons/badge')),
  calendar: defineAsyncComponent(() => import('./icons/calendar')),
  checkboxGroup: defineAsyncComponent(() => import('./icons/checkbox-group')),
  checkmark: defineAsyncComponent(() => import('./icons/checkmark')),
  checkbox: defineAsyncComponent(() => import('./icons/checkbox')),
  checkboxChecked: defineAsyncComponent(() =>
    import('./icons/checkbox-checked'),
  ),
  checkboxDefault: defineAsyncComponent(() =>
    import('./icons/checkbox-default'),
  ),
  chevron: defineAsyncComponent(() => import('./icons/chevron')),
  clock: defineAsyncComponent(() => import('./icons/clock')),
  close: defineAsyncComponent(() => import('./icons/close')),
  commentsDisabled: defineAsyncComponent(() =>
    import('./icons/comments-disabled'),
  ),
  comments: defineAsyncComponent(() => import('./icons/comments')),
  dataSource: defineAsyncComponent(() => import('./icons/data-source')),
  download: defineAsyncComponent(() => import('./icons/download')),
  drag: defineAsyncComponent(() => import('./icons/drag')),
  dropdown: defineAsyncComponent(() => import('./icons/dropdown')),
  filter: defineAsyncComponent(() => import('./icons/filter')),
  header: defineAsyncComponent(() => import('./icons/header')),
  hidden: defineAsyncComponent(() => import('./icons/hidden')),
  hide: defineAsyncComponent(() => import('./icons/hide')),
  home: defineAsyncComponent(() => import('./icons/home')),
  info: defineAsyncComponent(() => import('./icons/info')),
  instructions: defineAsyncComponent(() => import('./icons/instructions')),
  key: defineAsyncComponent(() => import('./icons/key')),
  location: defineAsyncComponent(() => import('./icons/location')),
  menu: defineAsyncComponent(() => import('./icons/menu')),
  more: defineAsyncComponent(() => import('./icons/more')),
  nextPage: defineAsyncComponent(() =>
    import('./icons/next-page-enabled-black'),
  ),
  previousPage: defineAsyncComponent(() => import('./icons/previous-page')),
  number: defineAsyncComponent(() => import('./icons/number')),
  paragraph: defineAsyncComponent(() => import('./icons/paragraph')),
  plus: defineAsyncComponent(() => import('./icons/plus')),
  plusEnclosed: defineAsyncComponent(() => import('./icons/plus-enclosed')),
  priorityHigh: defineAsyncComponent(() => import('./icons/priority-high')),
  priorityLow: defineAsyncComponent(() => import('./icons/priority-low')),
  priorityMedium: defineAsyncComponent(() => import('./icons/priority-medium')),
  priorities: defineAsyncComponent(() => import('./icons/priorities')),
  profile: defineAsyncComponent(() => import('./icons/profile')),
  radioButtonDefault: defineAsyncComponent(() =>
    import('./icons/radio-button-default'),
  ),
  radioButtonChecked: defineAsyncComponent(() =>
    import('./icons/radio-button-checked'),
  ),
  registrationExit: defineAsyncComponent(() =>
    import('./icons/registration-exit'),
  ),
  registration: defineAsyncComponent(() => import('./icons/registration')),
  remove: defineAsyncComponent(() => import('./icons/remove')),
  search: defineAsyncComponent(() => import('./icons/search')),
  select: defineAsyncComponent(() => import('./icons/select')),
  selectButton: defineAsyncComponent(() => import('./icons/selectButton')),
  truck: defineAsyncComponent(() => import('./icons/truck')),
  show: defineAsyncComponent(() => import('./icons/show')),
  signature: defineAsyncComponent(() => import('./icons/signature')),
  sort: defineAsyncComponent(() => import('./icons/sort')),
  system: defineAsyncComponent(() => import('./icons/system')),
  tasks: defineAsyncComponent(() => import('./icons/tasks')),
  textField: defineAsyncComponent(() => import('./icons/text-field')),
  textarea: defineAsyncComponent(() => import('./icons/textarea')),
  tickets: defineAsyncComponent(() => import('./icons/tickets')),
  upload: defineAsyncComponent(() => import('./icons/upload')),
  workflow: defineAsyncComponent(() => import('./icons/workflow')),
  logo: defineAsyncComponent(() => import('./icons/logo')),
  logoSmall: defineAsyncComponent(() => import('./icons/logo-small')),
};

export default defineNuxtComponent({
  name: 'Icon',
  components: { IconNfc },
  props: {
    name: {
      type: String,
      required: true,
    },
    sizeX: {
      type: [Number, String],
      default: 24,
    },
    sizeY: {
      type: [Number, String],
      default: 24,
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: '#0080FF',
    },
    count: {
      type: Number,
      default: 0,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconComponent() {
      return icons[this.name];
    },
  },
  methods: {},
});

export { icons };
</script>

<template>
  <span>
    <IconNfc v-if="name === 'nfc'" />
    <component
      :is="iconComponent"
      v-else
      :size="size"
      :size-x="sizeX"
      :size-y="sizeY"
      :accent="accent"
      :count="count"
      :dark-mode="darkMode"
      role="img"
    />
  </span>
</template>
