/**
 * --------------------------------------------------------------------
 * Create ticket store
 * --------------------------------------------------------------------
 */

export default {
  namespaced: true,
  state: () => ({
    createTicketTrigger: false,
    editTicketTrigger: false,
    fetchTicketsTrigger: false,
    locationId: undefined,
  }),
  getters: {
    locationId(state) {
      return state.locationId;
    },
    createTicketTrigger(state) {
      return state.createTicketTrigger;
    },
    editTicketTrigger(state) {
      return state.editTicketTrigger;
    },
    fetchTicketsTrigger(state) {
      return state.fetchTicketsTrigger;
    },
  },
  mutations: {
    createTicketTrigger: (state, value) => (state.createTicketTrigger = value),
    editTicketTrigger: (state, value) => (state.editTicketTrigger = value),
    fetchTicketsTrigger: state => (state.fetchTicketsTrigger = true),
    clearTicketStore: (state) => {
      state.createTicketTrigger = false;
      state.editTicketTrigger = false;
      state.fetchTicketsTrigger = false;
      state.locationId = undefined;
    },
  },
};
