export default {
  namespaced: true,
  state: () => ({
    modalLayout: '',
    modalType: '',
    modalTitle: '',
    modalOpened: false,
    modalData: {},
    modalAbilities: {},
    applyContentWidth: true,
  }),
  getters: {
    modalAbilities(state) {
      return state.modalAbilities;
    },
    modalLayout(state) {
      return state.modalLayout;
    },
    modalType(state) {
      return state.modalType;
    },
    modalTitle(state) {
      return state.modalTitle;
    },
    modalData(state) {
      return state.modalData;
    },
    modalOpened(state) {
      return state.modalOpened;
    },
    applyContentWidth(state) {
      return state.applyContentWidth;
    },
  },
  mutations: {
    resetData: (state) => {
      state.modalTitle = '';
      state.modalData = {};
      state.applyContentWidth = true;
      state.modalAbilities = {};
    },
    setModalAbilities: (state, modalAbilities) => {
      state.modalAbilities = modalAbilities;
    },
    setModalLayout: (state, modalLayout) => {
      state.modalLayout = modalLayout;
    },
    setModalType: (state, modalType) => {
      state.modalType = modalType;
    },
    setTitle: (state, modalTitle) => {
      state.modalTitle = modalTitle;
    },
    setModalData: (state, modalData) => {
      state.modalData = modalData;
    },
    setModalOpened: (state, modalOpened) => {
      state.modalOpened = modalOpened;
    },
    setApplyContentWidth: (state, applyContentWidth) => {
      state.applyContentWidth = applyContentWidth;
    },
  },
};
