import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as announcedhdvYRkJlDSMeta } from "/app/pages/access-request/announced.vue?macro=true";
import { default as signedinBoVubjXlgFMeta } from "/app/pages/access-request/signedin.vue?macro=true";
import { default as signedout34ZPdS8pe1Meta } from "/app/pages/access-request/signedout.vue?macro=true";
import { default as _91employeeId_9387ObYeSch9Meta } from "/app/pages/companies/[companyId]/employees/[employeeId].vue?macro=true";
import { default as employeesTt0bSOiYsWMeta } from "/app/pages/companies/[companyId]/employees.vue?macro=true";
import { default as indexPThto5Pu87Meta } from "/app/pages/companies/[companyId]/index.vue?macro=true";
import { default as _91locationId_93QJuCNJ0u1iMeta } from "/app/pages/companies/[companyId]/locations/[locationId].vue?macro=true";
import { default as locationsA3pkQZfSg6Meta } from "/app/pages/companies/[companyId]/locations.vue?macro=true";
import { default as _91companyId_93POOUDDvOWBMeta } from "/app/pages/companies/[companyId].vue?macro=true";
import { default as indexoLN6DBkdqCMeta } from "/app/pages/companies/index.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as editN2eUpL5HCYMeta } from "/app/pages/form-flows/index/[formFlowId]/item/[formFlowItemId]/edit.vue?macro=true";
import { default as _91formFlowItemId_93vYvIa4U1MIMeta } from "/app/pages/form-flows/index/[formFlowId]/item/[formFlowItemId].vue?macro=true";
import { default as _91formFlowId_936uUKF1vrFsMeta } from "/app/pages/form-flows/index/[formFlowId].vue?macro=true";
import { default as index6G4iIr3BkFMeta } from "/app/pages/form-flows/index.vue?macro=true";
import { default as activev1TFFrlZ9lMeta } from "/app/pages/form-template/active.vue?macro=true";
import { default as all5yIR2VZ7IkMeta } from "/app/pages/form-template/all.vue?macro=true";
import { default as stepsEtPFKAFiBCMeta } from "/app/pages/form-template/create/steps.js?macro=true";
import { default as _91status_93QHDRWkGrg9Meta } from "/app/pages/form-template/edit/[status].js?macro=true";
import { default as inactiveqcVjsdL1SkMeta } from "/app/pages/form-template/inactive.vue?macro=true";
import { default as editdQq0vY5AWmMeta } from "/app/pages/location/[locationId]/room/[roomId]/edit.vue?macro=true";
import { default as createzGTF82qJ7WMeta } from "/app/pages/location/[locationId]/room/create.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as reportingox84ohMv4kMeta } from "/app/pages/reporting.vue?macro=true";
import { default as scheduleRBheWBi7r1Meta } from "/app/pages/schedule.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as announcedthxHSjIx5bMeta } from "/app/pages/shipment/announced.vue?macro=true";
import { default as delivered07wJdW6uuRMeta } from "/app/pages/shipment/delivered.vue?macro=true";
import { default as receivedwgpVP2RYmxMeta } from "/app/pages/shipment/received.vue?macro=true";
import { default as switch_45deviceA7uRa1saYdMeta } from "/app/pages/switch-device.vue?macro=true";
import { default as impersonatensXsLKXTZ3Meta } from "/app/pages/system/user/_id/impersonate.vue?macro=true";
import { default as indexo7RJmE7eV9Meta } from "/app/pages/ticket/index.js?macro=true";
import { default as _91status_93R7zEe0ZZS2Meta } from "/app/pages/ticket/status/[status].js?macro=true";
import { default as forgot_45password874HsndeuRMeta } from "/app/node_modules/.pnpm/@workrate+nuxt-auth@1.0.17_@vuelidate+core@2.0.3_@vuelidate+validators@2.0.4_@vueuse+core@9.1_4kaiwf7ydtk36k56vifkcsgham/node_modules/@workrate/nuxt-auth/dist/runtime/pages/forgot-password.vue?macro=true";
import { default as activateYUlO0q7T4ZMeta } from "/app/node_modules/.pnpm/@workrate+nuxt-auth@1.0.17_@vuelidate+core@2.0.3_@vuelidate+validators@2.0.4_@vueuse+core@9.1_4kaiwf7ydtk36k56vifkcsgham/node_modules/@workrate/nuxt-auth/dist/runtime/pages/activate.vue?macro=true";
import { default as password_45reseth528IJf9H8Meta } from "/app/node_modules/.pnpm/@workrate+nuxt-auth@1.0.17_@vuelidate+core@2.0.3_@vuelidate+validators@2.0.4_@vueuse+core@9.1_4kaiwf7ydtk36k56vifkcsgham/node_modules/@workrate/nuxt-auth/dist/runtime/pages/password-reset.vue?macro=true";
import { default as verify_45tfa_45codedUJNcoHkoAMeta } from "/app/node_modules/.pnpm/@workrate+nuxt-auth@1.0.17_@vuelidate+core@2.0.3_@vuelidate+validators@2.0.4_@vueuse+core@9.1_4kaiwf7ydtk36k56vifkcsgham/node_modules/@workrate/nuxt-auth/dist/runtime/pages/verify-tfa-code.vue?macro=true";
import { default as nuxt_643_4611_462__64types_43node_6420_4612_467__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462T17IjcU39fMeta } from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@_brx5dwzjirugzwhxybgdew5id4/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64types_43node_6420_4612_467__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462T17IjcU39f } from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.12.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@_brx5dwzjirugzwhxybgdew5id4/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91_46_46_46slug_93ILszah2VNpMeta?.name ?? "slug",
    path: _91_46_46_46slug_93ILszah2VNpMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    alias: _91_46_46_46slug_93ILszah2VNpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ILszah2VNpMeta?.redirect,
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: announcedhdvYRkJlDSMeta?.name ?? "access-request-announced",
    path: announcedhdvYRkJlDSMeta?.path ?? "/access-request/announced",
    meta: announcedhdvYRkJlDSMeta || {},
    alias: announcedhdvYRkJlDSMeta?.alias || [],
    redirect: announcedhdvYRkJlDSMeta?.redirect,
    component: () => import("/app/pages/access-request/announced.vue").then(m => m.default || m)
  },
  {
    name: signedinBoVubjXlgFMeta?.name ?? "access-request-signedin",
    path: signedinBoVubjXlgFMeta?.path ?? "/access-request/signedin",
    meta: signedinBoVubjXlgFMeta || {},
    alias: signedinBoVubjXlgFMeta?.alias || [],
    redirect: signedinBoVubjXlgFMeta?.redirect,
    component: () => import("/app/pages/access-request/signedin.vue").then(m => m.default || m)
  },
  {
    name: signedout34ZPdS8pe1Meta?.name ?? "access-request-signedout",
    path: signedout34ZPdS8pe1Meta?.path ?? "/access-request/signedout",
    meta: signedout34ZPdS8pe1Meta || {},
    alias: signedout34ZPdS8pe1Meta?.alias || [],
    redirect: signedout34ZPdS8pe1Meta?.redirect,
    component: () => import("/app/pages/access-request/signedout.vue").then(m => m.default || m)
  },
  {
    name: _91companyId_93POOUDDvOWBMeta?.name ?? undefined,
    path: _91companyId_93POOUDDvOWBMeta?.path ?? "/companies/:companyId()",
    meta: _91companyId_93POOUDDvOWBMeta || {},
    alias: _91companyId_93POOUDDvOWBMeta?.alias || [],
    redirect: _91companyId_93POOUDDvOWBMeta?.redirect,
    component: () => import("/app/pages/companies/[companyId].vue").then(m => m.default || m),
    children: [
  {
    name: employeesTt0bSOiYsWMeta?.name ?? "companies-companyId-employees",
    path: employeesTt0bSOiYsWMeta?.path ?? "employees",
    meta: employeesTt0bSOiYsWMeta || {},
    alias: employeesTt0bSOiYsWMeta?.alias || [],
    redirect: employeesTt0bSOiYsWMeta?.redirect,
    component: () => import("/app/pages/companies/[companyId]/employees.vue").then(m => m.default || m),
    children: [
  {
    name: _91employeeId_9387ObYeSch9Meta?.name ?? "companies-companyId-employees-employeeId",
    path: _91employeeId_9387ObYeSch9Meta?.path ?? ":employeeId()",
    meta: _91employeeId_9387ObYeSch9Meta || {},
    alias: _91employeeId_9387ObYeSch9Meta?.alias || [],
    redirect: _91employeeId_9387ObYeSch9Meta?.redirect,
    component: () => import("/app/pages/companies/[companyId]/employees/[employeeId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexPThto5Pu87Meta?.name ?? "companies-companyId",
    path: indexPThto5Pu87Meta?.path ?? "",
    meta: indexPThto5Pu87Meta || {},
    alias: indexPThto5Pu87Meta?.alias || [],
    redirect: indexPThto5Pu87Meta?.redirect,
    component: () => import("/app/pages/companies/[companyId]/index.vue").then(m => m.default || m)
  },
  {
    name: locationsA3pkQZfSg6Meta?.name ?? "companies-companyId-locations",
    path: locationsA3pkQZfSg6Meta?.path ?? "locations",
    meta: locationsA3pkQZfSg6Meta || {},
    alias: locationsA3pkQZfSg6Meta?.alias || [],
    redirect: locationsA3pkQZfSg6Meta?.redirect,
    component: () => import("/app/pages/companies/[companyId]/locations.vue").then(m => m.default || m),
    children: [
  {
    name: _91locationId_93QJuCNJ0u1iMeta?.name ?? "companies-companyId-locations-locationId",
    path: _91locationId_93QJuCNJ0u1iMeta?.path ?? ":locationId()",
    meta: _91locationId_93QJuCNJ0u1iMeta || {},
    alias: _91locationId_93QJuCNJ0u1iMeta?.alias || [],
    redirect: _91locationId_93QJuCNJ0u1iMeta?.redirect,
    component: () => import("/app/pages/companies/[companyId]/locations/[locationId].vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexoLN6DBkdqCMeta?.name ?? "companies",
    path: indexoLN6DBkdqCMeta?.path ?? "/companies",
    meta: indexoLN6DBkdqCMeta || {},
    alias: indexoLN6DBkdqCMeta?.alias || [],
    redirect: indexoLN6DBkdqCMeta?.redirect,
    component: () => import("/app/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardJu37u7k0toMeta?.name ?? "dashboard",
    path: dashboardJu37u7k0toMeta?.path ?? "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    alias: dashboardJu37u7k0toMeta?.alias || [],
    redirect: dashboardJu37u7k0toMeta?.redirect,
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: index6G4iIr3BkFMeta?.name ?? "form-flows",
    path: index6G4iIr3BkFMeta?.path ?? "/form-flows",
    meta: index6G4iIr3BkFMeta || {},
    alias: index6G4iIr3BkFMeta?.alias || [],
    redirect: index6G4iIr3BkFMeta?.redirect,
    component: () => import("/app/pages/form-flows/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91formFlowId_936uUKF1vrFsMeta?.name ?? "form-flows-index-formFlowId",
    path: _91formFlowId_936uUKF1vrFsMeta?.path ?? ":formFlowId()",
    meta: _91formFlowId_936uUKF1vrFsMeta || {},
    alias: _91formFlowId_936uUKF1vrFsMeta?.alias || [],
    redirect: _91formFlowId_936uUKF1vrFsMeta?.redirect,
    component: () => import("/app/pages/form-flows/index/[formFlowId].vue").then(m => m.default || m),
    children: [
  {
    name: _91formFlowItemId_93vYvIa4U1MIMeta?.name ?? "form-flows-index-formFlowId-item-formFlowItemId",
    path: _91formFlowItemId_93vYvIa4U1MIMeta?.path ?? "item/:formFlowItemId()",
    meta: _91formFlowItemId_93vYvIa4U1MIMeta || {},
    alias: _91formFlowItemId_93vYvIa4U1MIMeta?.alias || [],
    redirect: _91formFlowItemId_93vYvIa4U1MIMeta?.redirect,
    component: () => import("/app/pages/form-flows/index/[formFlowId]/item/[formFlowItemId].vue").then(m => m.default || m),
    children: [
  {
    name: editN2eUpL5HCYMeta?.name ?? "form-flows-index-formFlowId-item-formFlowItemId-edit",
    path: editN2eUpL5HCYMeta?.path ?? "edit",
    meta: editN2eUpL5HCYMeta || {},
    alias: editN2eUpL5HCYMeta?.alias || [],
    redirect: editN2eUpL5HCYMeta?.redirect,
    component: () => import("/app/pages/form-flows/index/[formFlowId]/item/[formFlowItemId]/edit.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: activev1TFFrlZ9lMeta?.name ?? "form-template-active",
    path: activev1TFFrlZ9lMeta?.path ?? "/form-template/active",
    meta: activev1TFFrlZ9lMeta || {},
    alias: activev1TFFrlZ9lMeta?.alias || [],
    redirect: activev1TFFrlZ9lMeta?.redirect,
    component: () => import("/app/pages/form-template/active.vue").then(m => m.default || m)
  },
  {
    name: all5yIR2VZ7IkMeta?.name ?? "form-template-all",
    path: all5yIR2VZ7IkMeta?.path ?? "/form-template/all",
    meta: all5yIR2VZ7IkMeta || {},
    alias: all5yIR2VZ7IkMeta?.alias || [],
    redirect: all5yIR2VZ7IkMeta?.redirect,
    component: () => import("/app/pages/form-template/all.vue").then(m => m.default || m)
  },
  {
    name: stepsEtPFKAFiBCMeta?.name ?? "form-template-create-steps",
    path: stepsEtPFKAFiBCMeta?.path ?? "/form-template/create/steps",
    meta: stepsEtPFKAFiBCMeta || {},
    alias: stepsEtPFKAFiBCMeta?.alias || [],
    redirect: stepsEtPFKAFiBCMeta?.redirect,
    component: () => import("/app/pages/form-template/create/steps.js").then(m => m.default || m)
  },
  {
    name: _91status_93QHDRWkGrg9Meta?.name ?? "form-template-edit-status",
    path: _91status_93QHDRWkGrg9Meta?.path ?? "/form-template/edit/:status()",
    meta: _91status_93QHDRWkGrg9Meta || {},
    alias: _91status_93QHDRWkGrg9Meta?.alias || [],
    redirect: _91status_93QHDRWkGrg9Meta?.redirect,
    component: () => import("/app/pages/form-template/edit/[status].js").then(m => m.default || m)
  },
  {
    name: inactiveqcVjsdL1SkMeta?.name ?? "form-template-inactive",
    path: inactiveqcVjsdL1SkMeta?.path ?? "/form-template/inactive",
    meta: inactiveqcVjsdL1SkMeta || {},
    alias: inactiveqcVjsdL1SkMeta?.alias || [],
    redirect: inactiveqcVjsdL1SkMeta?.redirect,
    component: () => import("/app/pages/form-template/inactive.vue").then(m => m.default || m)
  },
  {
    name: editdQq0vY5AWmMeta?.name ?? "location-locationId-room-roomId-edit",
    path: editdQq0vY5AWmMeta?.path ?? "/location/:locationId()/room/:roomId()/edit",
    meta: editdQq0vY5AWmMeta || {},
    alias: editdQq0vY5AWmMeta?.alias || [],
    redirect: editdQq0vY5AWmMeta?.redirect,
    component: () => import("/app/pages/location/[locationId]/room/[roomId]/edit.vue").then(m => m.default || m)
  },
  {
    name: createzGTF82qJ7WMeta?.name ?? "location-locationId-room-create",
    path: createzGTF82qJ7WMeta?.path ?? "/location/:locationId()/room/create",
    meta: createzGTF82qJ7WMeta || {},
    alias: createzGTF82qJ7WMeta?.alias || [],
    redirect: createzGTF82qJ7WMeta?.redirect,
    component: () => import("/app/pages/location/[locationId]/room/create.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: reportingox84ohMv4kMeta?.name ?? "reporting",
    path: reportingox84ohMv4kMeta?.path ?? "/reporting",
    meta: reportingox84ohMv4kMeta || {},
    alias: reportingox84ohMv4kMeta?.alias || [],
    redirect: reportingox84ohMv4kMeta?.redirect,
    component: () => import("/app/pages/reporting.vue").then(m => m.default || m)
  },
  {
    name: scheduleRBheWBi7r1Meta?.name ?? "schedule",
    path: scheduleRBheWBi7r1Meta?.path ?? "/schedule",
    meta: scheduleRBheWBi7r1Meta || {},
    alias: scheduleRBheWBi7r1Meta?.alias || [],
    redirect: scheduleRBheWBi7r1Meta?.redirect,
    component: () => import("/app/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search",
    path: searchfcnTL5fl5XMeta?.path ?? "/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: announcedthxHSjIx5bMeta?.name ?? "shipment-announced",
    path: announcedthxHSjIx5bMeta?.path ?? "/shipment/announced",
    meta: announcedthxHSjIx5bMeta || {},
    alias: announcedthxHSjIx5bMeta?.alias || [],
    redirect: announcedthxHSjIx5bMeta?.redirect,
    component: () => import("/app/pages/shipment/announced.vue").then(m => m.default || m)
  },
  {
    name: delivered07wJdW6uuRMeta?.name ?? "shipment-delivered",
    path: delivered07wJdW6uuRMeta?.path ?? "/shipment/delivered",
    meta: delivered07wJdW6uuRMeta || {},
    alias: delivered07wJdW6uuRMeta?.alias || [],
    redirect: delivered07wJdW6uuRMeta?.redirect,
    component: () => import("/app/pages/shipment/delivered.vue").then(m => m.default || m)
  },
  {
    name: receivedwgpVP2RYmxMeta?.name ?? "shipment-received",
    path: receivedwgpVP2RYmxMeta?.path ?? "/shipment/received",
    meta: receivedwgpVP2RYmxMeta || {},
    alias: receivedwgpVP2RYmxMeta?.alias || [],
    redirect: receivedwgpVP2RYmxMeta?.redirect,
    component: () => import("/app/pages/shipment/received.vue").then(m => m.default || m)
  },
  {
    name: switch_45deviceA7uRa1saYdMeta?.name ?? "switch-device",
    path: switch_45deviceA7uRa1saYdMeta?.path ?? "/switch-device",
    meta: switch_45deviceA7uRa1saYdMeta || {},
    alias: switch_45deviceA7uRa1saYdMeta?.alias || [],
    redirect: switch_45deviceA7uRa1saYdMeta?.redirect,
    component: () => import("/app/pages/switch-device.vue").then(m => m.default || m)
  },
  {
    name: impersonatensXsLKXTZ3Meta?.name ?? "system-user-_id-impersonate",
    path: impersonatensXsLKXTZ3Meta?.path ?? "/system/user/_id/impersonate",
    meta: impersonatensXsLKXTZ3Meta || {},
    alias: impersonatensXsLKXTZ3Meta?.alias || [],
    redirect: impersonatensXsLKXTZ3Meta?.redirect,
    component: () => import("/app/pages/system/user/_id/impersonate.vue").then(m => m.default || m)
  },
  {
    name: indexo7RJmE7eV9Meta?.name ?? "ticket",
    path: indexo7RJmE7eV9Meta?.path ?? "/ticket",
    meta: indexo7RJmE7eV9Meta || {},
    alias: indexo7RJmE7eV9Meta?.alias || [],
    redirect: indexo7RJmE7eV9Meta?.redirect,
    component: () => import("/app/pages/ticket/index.js").then(m => m.default || m)
  },
  {
    name: _91status_93R7zEe0ZZS2Meta?.name ?? "ticket-status-status",
    path: _91status_93R7zEe0ZZS2Meta?.path ?? "/ticket/status/:status()",
    meta: _91status_93R7zEe0ZZS2Meta || {},
    alias: _91status_93R7zEe0ZZS2Meta?.alias || [],
    redirect: _91status_93R7zEe0ZZS2Meta?.redirect,
    component: () => import("/app/pages/ticket/status/[status].js").then(m => m.default || m)
  },
  {
    name: forgot_45password874HsndeuRMeta?.name ?? "Forgot Password",
    path: forgot_45password874HsndeuRMeta?.path ?? "/forgot-password",
    meta: forgot_45password874HsndeuRMeta || {},
    alias: forgot_45password874HsndeuRMeta?.alias || [],
    redirect: forgot_45password874HsndeuRMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/@workrate+nuxt-auth@1.0.17_@vuelidate+core@2.0.3_@vuelidate+validators@2.0.4_@vueuse+core@9.1_4kaiwf7ydtk36k56vifkcsgham/node_modules/@workrate/nuxt-auth/dist/runtime/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: activateYUlO0q7T4ZMeta?.name ?? "Activate Account",
    path: activateYUlO0q7T4ZMeta?.path ?? "/activate/:token",
    meta: activateYUlO0q7T4ZMeta || {},
    alias: activateYUlO0q7T4ZMeta?.alias || [],
    redirect: activateYUlO0q7T4ZMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/@workrate+nuxt-auth@1.0.17_@vuelidate+core@2.0.3_@vuelidate+validators@2.0.4_@vueuse+core@9.1_4kaiwf7ydtk36k56vifkcsgham/node_modules/@workrate/nuxt-auth/dist/runtime/pages/activate.vue").then(m => m.default || m)
  },
  {
    name: password_45reseth528IJf9H8Meta?.name ?? "Reset Password",
    path: password_45reseth528IJf9H8Meta?.path ?? "/password/reset/:token",
    meta: password_45reseth528IJf9H8Meta || {},
    alias: password_45reseth528IJf9H8Meta?.alias || [],
    redirect: password_45reseth528IJf9H8Meta?.redirect,
    component: () => import("/app/node_modules/.pnpm/@workrate+nuxt-auth@1.0.17_@vuelidate+core@2.0.3_@vuelidate+validators@2.0.4_@vueuse+core@9.1_4kaiwf7ydtk36k56vifkcsgham/node_modules/@workrate/nuxt-auth/dist/runtime/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: verify_45tfa_45codedUJNcoHkoAMeta?.name ?? "Verify TFA Code",
    path: verify_45tfa_45codedUJNcoHkoAMeta?.path ?? "/tfa/verify",
    meta: verify_45tfa_45codedUJNcoHkoAMeta || {},
    alias: verify_45tfa_45codedUJNcoHkoAMeta?.alias || [],
    redirect: verify_45tfa_45codedUJNcoHkoAMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/@workrate+nuxt-auth@1.0.17_@vuelidate+core@2.0.3_@vuelidate+validators@2.0.4_@vueuse+core@9.1_4kaiwf7ydtk36k56vifkcsgham/node_modules/@workrate/nuxt-auth/dist/runtime/pages/verify-tfa-code.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64types_43node_6420_4612_467__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462T17IjcU39fMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64types_43node_6420_4612_467__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462T17IjcU39fMeta?.path ?? "/",
    meta: nuxt_643_4611_462__64types_43node_6420_4612_467__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462T17IjcU39fMeta || {},
    alias: nuxt_643_4611_462__64types_43node_6420_4612_467__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462T17IjcU39fMeta?.alias || [],
    redirect: nuxt_643_4611_462__64types_43node_6420_4612_467__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462T17IjcU39fMeta?.redirect,
    component: nuxt_643_4611_462__64types_43node_6420_4612_467__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462T17IjcU39f
  }
]