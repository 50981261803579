/**
 * --------------------------------------------------------------------
 * Translation store
 * --------------------------------------------------------------------
 */

export default {
  namespaced: true,
  state: () => ({
    translations: [],
    build: undefined,
    language: undefined,
  }),
  actions: {
    async fetchTranslations({ commit, getters }, defaultLocale) {
      const { $config } = useNuxtApp();
      const locale = defaultLocale || 'en';
      const build
        = $config.public.BUILD_HASH || new Date().toISOString().slice(0, 10);
      try {
        if (getters.fetchTranslations(build, locale)) {
          const headers = useRequestHeaders(['cookie']);
          const data = await $fetch('/api/v2/translations', {
            headers,
            query: {
              locale,
            },
          });

          commit('setTranslations', data);
          commit('setBuild', build);
          commit('setLanguage', locale);
        }
      }
      catch {
        // Error
      }
    },
  },
  getters: {
    translations: state => state.translations,
    build: state => state.build,
    language: state => state.language,

    getTranslation: state => (translation) => {
      if (state.translations) {
        return Object.prototype.hasOwnProperty.call(
          state.translations,
          translation,
        ) && state.translations[translation] !== null
          ? state.translations[translation]
          : translation;
      }

      return translation;
    },

    /**
     * Check to see if we need to fetch translations
     * @param state
     * @return {boolean}
     */
    fetchTranslations: state => (build, language) =>
      Boolean(
        state.build === false
        || state.language === false
        || state.build !== build
        || state.language !== language,
      ),
  },
  mutations: {
    /**
     * Set translations
     * @param state
     * @param translations
     * @return {*}
     */
    setTranslations: (state, translations) =>
      (state.translations = translations),

    /**
     * Set build
     * @param state
     * @param build
     * @return {*}
     */
    setBuild: (state, build) => (state.build = build),

    /**
     * Set language
     * @param state
     * @param language
     * @return {*}
     */
    setLanguage: (state, language) => (state.language = language),
  },
};
