import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { computed } from 'vue';

export default defineNuxtPlugin((nuxt) => {
  window.Pusher = Pusher;
  const { appContext } = useAppContext();
  const runtimeConfig = useRuntimeConfig();
  const websocket = computed(() => appContext.value?.app?.websocket);

  const echo = new Echo({
    broadcaster: 'pusher',
    key: '084782c076dfce46afcf5d3aa7955787',
    cluster: 'mt1',
    wsHost: runtimeConfig.public.WS_URL || websocket.value || 'localhost',
    forceTLS: false,
    wsPort: 6001,
    enabledTransports: ['ws', 'wss'],
    disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming'],
    disableStats: true,
  });
  nuxt.provide('echo', echo);
});
