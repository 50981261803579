<script>
import { lowerFirst } from 'lodash-es';
import { mapGetters } from 'vuex';

import DisplayMixin from '@/mixins/DisplayMixin';
import FilteringControllerMixin from '@/mixins/FilteringControllerMixin';
import { LOCATION_FILTER, TICKETS_MODULE } from '~/features/tickets/constants';
import TicketsManagerMixin from '~/features/tickets/mixins/TicketsManagerMixin';

const layoutTypes = {
  mobile: defineAsyncComponent(() => import('./Mobile')),
  tablet: defineAsyncComponent(() => import('./Tablet')),
  desktop: defineAsyncComponent(() => import('./Desktop')),
  small_desktop: defineAsyncComponent(() => import('./Desktop')),
};

const FILTERS = [
  'location',
  'group',
  'priority',
  'assignee',
  'ticket_category',
  'ticket_type',
];
const FIND_TICKET_QUERY = 'q';
const TICKET_DATE = 'ticket_date';

export default defineNuxtComponent({
  setup() {
    useHead({
      title: 'Tickets',
    });
  },
  name: 'TicketHeading',
  mixins: [TicketsManagerMixin, DisplayMixin, FilteringControllerMixin],
  provide() {
    return {
      clearFilters: this.onClearFilters,
    };
  },
  data() {
    return {
      ticketStatuses: [],
      ticketStatus: {},
      sortCriteria: [
        {
          name: this.__('ID'),
          description: this.__('ID'),
          checked: true,
          value: 'id',
          icon: 'tickets',
        },
        {
          name: this.__('Created'),
          description: this.__('Creation date'),
          checked: false,
          value: 'ticket_date',
          icon: 'add-outline',
        },
        {
          name: this.__('Updated'),
          description: this.__('Last updated'),
          checked: false,
          value: 'updated_at',
          icon: 'clock',
        },
        {
          name: this.__('Deadline'),
          description: this.__('Deadline date'),
          checked: false,
          value: 'deadline_date',
          icon: 'calendar',
        },
      ],
    };
  },
  async created() {
    this.setDefaultSortingParams(this.$route.params.status);
    this.setProcessingAction(true);

    this.ticketStatuses = await this.retrieveTicketStatuses();
    this.ticketStatus = this.fetchTicketStatus();
  },
  beforeMount() {
    this.listen();
  },
  beforeUnmount() {
    this.listen('off');
  },
  computed: {
    ...mapGetters('TicketsModuleStore', [
      'sortingParams',
      'selectedTickets',
      'fetchedTickets',
    ]),
    ...mapGetters('LocationsStore', ['selectedLocation']),
    ticketsNumber() {
      return this.fetchedTickets?.meta?.total || 0;
    },
    layoutTypeComponent() {
      return layoutTypes[this.getDisplayMode];
    },
    isOnTicketOverview() {
      return this.$route.path.includes('/ticket');
    },
  },
  watch: {
    selectedLocation() {
      this.onClearFilters();
    },
    async $route() {
      if (
        this.$route.params.status === this.sortingParams.status_id
          || !this.isOnTicketOverview
      )
        return;

      this.setSortingParams({
        ...this.sortingParams,
        status_id: this.$route.params.status,
      });
      this.clearSelectedTickets();
      await this.retrieveTickets();
      this.ticketStatus = this.fetchTicketStatus();
    },
  },
  mounted() {
    this.onClearFilters();
    this.registerFilteringCallbacks();
  },
  methods: {
    listen(status = 'on') {
      this.$eventBus[`$${status}`](
        'onRemoveSortingParamsFiltering',
        this.onClearFilters,
      );
    },
    onExportSelectedTickets() {
      const ticketIds = this.selectedTickets.map(ticket => ticket.id);

      let exportParameters = [];

      if (ticketIds.length === 0) {
        for (const key of Object.keys(this.sortingParams)) {
          if (this.sortingParams[key] !== undefined)
            exportParameters.push(`${key}=${this.sortingParams[key]}`);
        }
      }
      else {
        exportParameters = [`id[in]=${ticketIds.join(',')}`];
      }

      return window.open(
        `${this.$baseURL}/api/v2/ticket/export-excel?${exportParameters.join('&')}`,
        `_BLANK`,
      );
    },
    registerFilteringCallbacks() {
      for (const filter of FILTERS) {
        this.registerFilteringCallback(
          TICKETS_MODULE,
          filter,
          (name, values) => {
            this.setTicketsFilter(name, values, true);
          },
        );
      }

      this.registerFilteringCallback(
        TICKETS_MODULE,
        TICKET_DATE,
        (name, values) => {
          this.setTicketsDateFilter(name, values, true);
        },
      );

      this.registerFilteringCallback(
        TICKETS_MODULE,
        FIND_TICKET_QUERY,
        (name, values) => {
          this.setTicketsQueryFilter(name, values, true);
        },
      );
    },
    setTicketsQueryFilter(name, values, forceRetrieve) {
      this.setSortingParams({
        ...this.sortingParams,
        [name]:
          values && values.length > 0 ? values[values.length - 1] : undefined,
      });
      if (forceRetrieve)
        this.retrieveTickets();
    },
    fetchTicketStatus() {
      const ticketStatus = this.ticketStatuses.find(
        element => element.id === Number.parseInt(this.$route.params.status),
      );
      if (!ticketStatus) {
        return {
          description: 'All tickets',
          name: 'All',
        };
      }

      return ticketStatus || {};
    },
    onSortCriteriaChanged(sortParameter, selectedCriteria) {
      this.setSortingParams({
        ...this.sortingParams,
        sort: sortParameter,
      });
      for (const criteria of this.sortCriteria)
        criteria.checked = criteria.name === selectedCriteria.name;

      this.retrieveTickets();
    },
    setTicketsDateFilter(name, dates, forceRetrieve) {
      const currentParameters = this.sortingParams;
      if (dates?.length && dates[0] && dates[1]) {
        currentParameters[`${name}[gte]`] = dates[0].toISOString();
        currentParameters[`${name}[lte]`] = dates[1].toISOString();
      }
      else {
        currentParameters[`${name}[gte]`] = undefined;
        currentParameters[`${name}[lte]`] = undefined;
      }

      this.setSortingParams(currentParameters);

      if (forceRetrieve)
        this.retrieveTickets();
    },
    setTicketsFilter(name, ids, forceRetrieve) {
      const filterIds = [];
      const currentParameters = this.sortingParams;
      if (ids) {
        for (const element of ids)
          filterIds.push(element.id);

        currentParameters[`${lowerFirst(name)}_id[in]`] = filterIds.join(',');
      }
      else {
        currentParameters[`${lowerFirst(name)}_id[in]`]
          = name === LOCATION_FILTER ? this.selectedLocation.id : undefined;
      }
      this.setSortingParams(currentParameters);
      if (forceRetrieve)
        this.retrieveTickets();
    },
    onTicketStatusChanged(event) {
      event.id
        ? this.$router.push(`/ticket/status/${event.id}`)
        : this.$router.push(`/ticket/`);
    },
    onClearFilters() {
      this.clearFilter();
      this.removeSortingParamsFiltering();
      this.$eventBus.$emit('onClearSelection');
      this.retrieveTickets();
    },
    removeSortingParamsFiltering() {
      for (const filter of FILTERS)
        this.setTicketsFilter(filter, undefined, false);

      this.setTicketsDateFilter(TICKET_DATE, undefined, false);
      this.setTicketsQueryFilter(FIND_TICKET_QUERY, undefined, false);
    },
  },
});
</script>

<template>
  <div>
    <component
      :is="layoutTypeComponent"
      :ticket-status="ticketStatus"
      :tickets-number="ticketsNumber"
      :sort-criteria="sortCriteria"
      @set-tickets-filter="setTicketsFilter"
      @on-sort-criteria-changed="onSortCriteriaChanged"
      @on-ticket-status-changed="onTicketStatusChanged"
      @on-clear-filters="onClearFilters"
      @export-selected-tickets="onExportSelectedTickets"
    />
  </div>
</template>
