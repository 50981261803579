<script>
import draggable from 'vuedraggable';

import { formTemplateElements } from '../../../constants';
import InlineEdit from '@/components/InlineEdit';

import TextInput from '@/components/atoms/TextInput';
import Icon from '~/components/atoms/Icon';

const formTemplateComponents = {
  'header': defineAsyncComponent(() =>
    import(
      '../../molecules/Templates/HeaderSubheadingTemplate'
    ),
  ),
  'paragraph': defineAsyncComponent(() =>
    import(
      '../../molecules/Templates/ParagraphTemplate'
    ),
  ),
  'textarea': defineAsyncComponent(() =>
    import(
      '../../molecules/Templates/TextAreaTemplate'
    ),
  ),
  'text': defineAsyncComponent(() =>
    import(
      '../../molecules/Templates/TextFieldTemplate'
    ),
  ),
  'radio-group': defineAsyncComponent(() =>
    import(
      '../../molecules/Templates/SelectionButtonTemplate'
    ),
  ),
  'checkbox': defineAsyncComponent(() =>
    import(
      '../../molecules/Templates/CheckboxTemplate'
    ),
  ),
  'checkbox-group': defineAsyncComponent(() =>
    import(
      '../../molecules/Templates/CheckboxGroupTemplate'
    ),
  ),
  'select': defineAsyncComponent(() =>
    import(
      '../../molecules/Templates/DropdownTemplate'
    ),
  ),
  'date': defineAsyncComponent(() =>
    import(
      '../../molecules/Templates/DateFieldTemplate'
    ),
  ),
  'datetime': defineAsyncComponent(() =>
    import(
      '../../molecules/Templates/DateTimeFieldTemplate'
    ),
  ),
  'file': defineAsyncComponent(() =>
    import(
      '../../molecules/Templates/FileUploadTemplate'
    ),
  ),
  'nfc': defineAsyncComponent(() =>
    import(
      '../../molecules/Templates/NFCFieldTemplate'
    ),
  ),
};

export default defineNuxtComponent({
  name: 'FormTemplateStep',
  components: { TextInput, Icon, draggable, InlineEdit },
  props: {
    index: {
      type: Number,
      required: true,
    },
    steps: {
      type: Array,
      default: () => [],
    },
    stepData: {
      type: Object,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      stepTitle: this?.stepData?.title || `Step ${this.stepData?.order}`,
    };
  },
  computed: {
    stepElements() {
      return this.stepData.elements;
    },
  },
  watch: {
    stepTitle(newValue) {
      this.$emit('changeStepElement', { ...this.stepData, title: newValue });
    },
  },
  beforeMount() {
    this.listen();
  },
  beforeUnmount() {
    this.listen('off');
  },
  methods: {
    listen(status = 'on') {
      this.$eventBus[`$${status}`]('onElementChanged', this.onChangeElement);
      this.$eventBus[`$${status}`]('onElementRemoved', this.onRemoveElement);
    },
    onAdd(formTemplate) {
      this.$emit('onElementDropped', {
        elementType: formTemplateElements[formTemplate.oldIndex].type,
        stepOrder: this.stepData.order,
      });
    },
    onChangeOrder(draggedContext) {
      if (draggedContext.moved)
        this.$emit('changeStepElement', { ...this.stepData, title: this.stepTitle });
    },
    generateDynamicElement(stepElement) {
      return formTemplateComponents[stepElement.type];
    },
    onRemoveElement({ stepElement, stepId }) {
      if (this.stepData.id === stepId) {
        this.$emit('removeStepElement', {
          stepData: { ...this.stepData, title: this.steptitle },
          stepElement,
        });
      }
    },
    onChangeElement(stepId) {
      if (this.stepData.id === stepId)
        this.$emit('changeStepElement', { ...this.stepData, title: this.stepTitle });
    },
    onDelete() {
      this.$emit('removeStep', this.stepData);
    },
  },
});
</script>

<template>
  <div class="flex flex-col rounded bg-white p-4">
    <div class="mt-2 flex flex-col space-y-4">
      <div class="flex">
        <InlineEdit :value="stepTitle">
          <div class="font-medium">
            {{ stepTitle }}
          </div>

          <template #edit="{ toggle, value, setValue }">
            <div class="inline-flex items-center gap-2">
              <input :value="value" class="rounded border border-neutral-2 px-2 py-1  font-medium" @input="setValue($event.target.value)">
              <button
                class="rounded border border-primary bg-primary px-2 py-1 text-sm font-medium text-white" @click="() => {
                  stepTitle = value;
                  toggle()
                }"
              >
                Save
              </button>
              <button class="rounded border border-neutral-2 px-2 py-1 text-sm font-medium text-neutral-2" @click="toggle()">
                Cancel
              </button>
            </div>
          </template>
        </InlineEdit>
        <div class="ml-auto flex items-center justify-end gap-2">
          <template v-if="steps.length > 1">
            <button v-if="index > 0" class="inline-flex h-6 w-6 items-center justify-center rounded border border-neutral-2 p-2 transition-colors hover:border-primary hover:text-primary" @click="$emit('onStepMove', { id: stepData.id, index: index - 1 })">
              <span class="icon-[ph--arrow-up-bold] h-3 w-3 flex-none" />
            </button>
            <button v-if="index !== steps.length - 1" class="inline-flex h-6 w-6 items-center justify-center rounded border border-neutral-2 p-2 transition-colors hover:border-primary hover:text-primary" @click="$emit('onStepMove', { id: stepData.id, index: index + 1 })">
              <span class="icon-[ph--arrow-down-bold] h-3 w-3 flex-none" />
            </button>
          </template>
          <div
            v-if="canDelete"
            class="flex cursor-pointer border-0 p-4"
            @click="onDelete"
          >
            <trailblazer-icon accent="var(--tb-color-error)" name="close" />
          </div>
        </div>
      </div>

      <div>
        <draggable
          class="flex min-h-12 flex-col gap-6"
          :class="{ 'rounded border border-dashed border-neutral-2 py-4': !stepElements.length }"
          animation="300"
          :list="stepElements"
          :group="{
            name: `step-${stepData.id}`,
            put: 'stepElements',
          }"
          item-key="id"
          handle=".component-dragger"
          ghost-class="ghost"
          :on-change="onChangeOrder"
          :on-add="onAdd"
        >
          <template #item="{ element }">
            <div>
              <component
                :is="generateDynamicElement(element)"
                :step-element="element"
                :step-id="stepData.id"
                @on-element-changed="onChangeElement"
              />
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>
