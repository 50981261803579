<script>
import draggable from 'vuedraggable';
import { useQueryClient } from '@tanstack/vue-query';
import { mapGetters } from 'vuex';

import FormTemplateStep from '../../components/organisms/FormTemplateStep';
import { formTemplateElements } from '../../constants';
import FormTemplateManager from '../../mixins/FormTemplateManager';
import ConfirmationModalControllerMixin from '@/mixins/ConfirmationModalControllerMixin';
import ToastManagerMixin from '@/mixins/ToastManagerMixin';
import ColorUtilities from '@/utilities/ColorUtilities';
import isEmptyObject from '~/utilities/isEmptyObject';
import FormFlowManagerMixin from '~/features/form-flows/mixins/FormFlowManagerMixin';

import Icon from '@/components/atoms/Icon';

export default defineNuxtComponent({
  setup() {
    const __ = useTranslate();
    const route = useRoute();
    const queryClient = useQueryClient();

    useHead({
      title: `${__(route.path.includes('edit') ? 'Edit' : 'Create')} - ${__(
        'Form Templates',
      )}`,
    });

    return { queryClient };
  },
  components: { draggable, FormTemplateStep, Icon },
  mixins: [
    FormFlowManagerMixin,
    FormTemplateManager,
    ConfirmationModalControllerMixin,
    ToastManagerMixin,
  ],
  data() {
    return {
      formTemplateElements,
      stepsCopy: [],
    };
  },
  watch: {
    async selectedLocation() {
      if (!Number.isNaN(this.$route.params.status)) {
        if (isEmptyObject(this.formTemplateData)) {
          await this.openFormTemplate(this.$route);
          await this.mountSteps();
        }
        else {
          this.$router.push('/form-template/all');
        }
      }
    },
  },
  async mounted() {
    if (
      isEmptyObject(this.formTemplateData)
      && !isNumber(this.$route.params.status)
      && this.selectedLocation.id
    )
      await this.openFormTemplate(this.$route);

    await this.mountSteps();
  },
  computed: {
    ...mapGetters('FormTemplateModuleStore', [
      'formTemplateData',
      'editableSteps',
    ]),
    ...mapGetters('FormFlowsModuleStore', ['processingAction']),
    ...mapGetters('LocationsStore', ['selectedLocation']),
    primaryColor() {
      return ColorUtilities.PRIMARY;
    },
    activeTemplateStatus() {
      return this.formTemplateData.status === 'Active';
    },
    isOnEditMode() {
      return this.$route.path.includes('edit');
    },
    headTitle() {
      return this.isOnEditMode ? 'Edit' : 'Create';
    },
  },
  beforeMount() {
    this.listen();
  },
  beforeUnmount() {
    this.listen('off');
  },
  async created() {
    const response = await this.retrieveFormTemplate(this.$route.params.status);
    this.$store.commit(
      'FormTemplateModuleStore/setFormTemplateSettings',
      response?.data?.settings,
    );
  },
  methods: {
    clone(formTemplate) {
      return this.generateElement(undefined, formTemplate.type).props.element;
    },
    async mountSteps() {
      this.setDefaultSortingParams();
      this.$store.commit('FormTemplateModuleStore/resetFormsTemplate');
      const stepsResponse = await this.retrieveFormFlowSteps(
        this.formTemplateData,
      );

      if (!stepsResponse)
        return;

      if (stepsResponse.length === 0) {
        const defaultStep = await this.createEmptyStep();
        stepsResponse.push(defaultStep.data);
      }

      this.$store.commit(
        'FormTemplateModuleStore/setEditableSteps',
        stepsResponse,
      );
      // Necessary since step order is not updated on backend
      this.recomputeStepOrder();

      this.stepsCopy = this.editableSteps;
    },
    async openFormTemplate(route) {
      const formTemplate = await this.retrieveFormTemplate(route.params.status);
      formTemplate
        ? this.$store.commit(
          'FormTemplateModuleStore/setFormTemplateData',
          formTemplate.data,
        )
        : this.$router.push(`/form-template/all`);
    },
    listen(status = 'on') {
      this.$eventBus[`$${status}`](
        'elementOrderChanged',
        this.onElementOrderChanged,
      );
    },
    computeOrder() {
      return this.editableSteps.length === 0
        ? 1
        : this.editableSteps.length + 1;
    },
    async goToFormTemplateOverview() {
      await this.$router.push('/form-template/all');
    },
    async createEmptyStep() {
      return await this.createFormTemplateStep({
        title: `Step ${this.computeOrder()}`,
        order: this.computeOrder(),
        elements: [
        ],
      });
    },
    async onAddNewStep() {
      const newStep = await this.createEmptyStep();
      this.$store.commit(
        'FormTemplateModuleStore/addEditableStep',
        newStep.data,
      );
    },
    onCancelFormEdit() {
      this.$dialog.$confirm({
        title: this.__('Close form template edit'),
        contents: this.__(
          'Are you sure you want to close this dialog? Changes you made will not be saved.',
        ),
        confirmButtonText: this.__('Close'),
        cancelButtonText: this.__('Cancel'),
        beforeClose: async (action, _instance, done) => {
          if (this.processingAction) {
            done();
            return;
          }
          if (action === 'confirm') {
            this.setProcessingAction(true);
            await this.deleteAddedSteps();
            await this.goToFormTemplateOverview();
            this.setProcessingAction(false);
          }
          done();
        },
      });
    },
    onCloseTemplate() {
      if (this.isOnEditMode) {
        this.$dialog.$confirm({
          title: this.__('Close form template'),
          contents: this.__('Do you want to save your process before closing?'),
          confirmButtonText: this.__('Save'),
          cancelButtonText: this.__('Cancel'),
          beforeClose: async (action, _instance, done) => {
            if (this.processingAction) {
              done();
              return;
            }
            if (action === 'confirm') {
              this.setProcessingAction(true);
              try {
                await this.updateTemplateSteps();
                hj('event', 'form_template_update');
                this.setProcessingAction(false);
              }
              catch {
                hj('event', 'form_template_update_fail');
                this.$toasty.$error({
                  title: this.__('Something went wrong'),
                  message: this.__('Failed to update template'),
                  autoDismiss: 5000,
                  callback: () => {},
                });
                this.setProcessingAction(false);
                done();
              }
              done();
              await this.goToFormTemplateOverview();
            }
            else {
              try {
                this.setProcessingAction(true);
                await this.deleteAddedSteps();
                this.setProcessingAction(false);
              }
              catch {
                hj('event', 'form_template_rollback_fail');
                this.$toasty.$error({
                  title: this.__('Something went wrong'),
                  message: this.__('Failed to rollback changes'),
                  autoDismiss: 5000,
                  callback: () => {},
                });
                this.setProcessingAction(false);
                done();
              }
              done();
              await this.goToFormTemplateOverview();
            }
          },
        });
      }
      else {
        this.$toasty.$success({
          title: this.__(`Form template created`),
          message: this.__(this.formTemplateData.name),
          autoDismiss: 5000,
          callback: () => {},
        });

        this.queryClient.invalidateQueries({ queryKey: ['formFlows'] });
        this.queryClient.invalidateQueries({ queryKey: ['locationCache'] });

        this.$router.push('/form-template/all');
      }
    },
    onUnpublishTemplate() {
      this.$dialog.$delete({
        title: this.__('Unpublish template'),
        contents: this.__(
          'Are you sure you want to unpublish and save your changes?',
        ),
        confirmButtonText: this.__('Yes'),
        cancelButtonText: this.__('No'),
        type: 'primary',
        beforeClose: async (action, _, done) => {
          if (this.processingAction || action !== 'confirm') {
            done();
            return;
          }
          this.setProcessingAction(true);
          try {
            await this.updateFormTemplate(this.formTemplateData.id, {
              id: this.formTemplateData.id,
              name: this.formTemplateData.name,
              status: 'inactive',
            });

            hj('event', 'form_template_unpublish');
            if (this.isOnEditMode)
              await this.updateTemplateSteps();
          }
          catch {
            hj('event', 'form_template_unpublish_fail');
            this.$toasty.$error({
              title: this.__('Something went wrong'),
              message: this.__('Failed to unpublish template'),
              autoDismiss: 5000,
              callback: () => {},
            });
          }
          this.setProcessingAction(false);
          done();

          this.queryClient.invalidateQueries({ queryKey: ['formFlows'] });
          this.queryClient.invalidateQueries({ queryKey: ['locationCache'] });
          await this.goToFormTemplateOverview();
        },
      });
    },
    async updateTemplateSteps() {
      const stepOrder = { stepIds: this.editableSteps.map(step => step.id) };
      if (!(await this.updateFormTemplateStepOrder(stepOrder)))
        return;

      for (const step of this.editableSteps) {
        if (!(await this.updateFormTemplateStep(step)))
          return;
      }

      hj('event', 'form_template_saved');
      this.$toasty.$success({
        title: this.__(`Form template saved`),
        message: this.__(this.formTemplateData.name),
        autoDismiss: 5000,
        callback: () => {},
      });

      this.queryClient.invalidateQueries({ queryKey: ['formFlows'] });
      this.queryClient.invalidateQueries({ queryKey: ['locationCache'] });
      await this.goToFormTemplateOverview();
    },
    async onPublishTemplate() {
      try {
        if (this.processingAction)
          return;

        this.setProcessingAction(true);
        await this.updateFormTemplate(this.formTemplateData.id, {
          id: this.formTemplateData.id,
          name: this.formTemplateData.name,
          status: 'active',
        });
        if (this.isOnEditMode) {
          await this.updateTemplateSteps();
        }
        else {
          hj('event', 'form_template_create');

          this.$toasty.$success({
            title: this.__(`Form template created`),
            message: this.__(this.formTemplateData.name),
            autoDismiss: 5000,
            callback: () => {},
          });

          this.queryClient.invalidateQueries({ queryKey: ['formFlows'] });
          this.queryClient.invalidateQueries({ queryKey: ['locationCache'] });
        }
        this.setProcessingAction(false);
      }
      catch {
        hj('event', 'form_template_create_fail');

        this.$toasty.$error({
          title: this.__('Something went wrong'),
          message: this.__('Failed to publish template'),
          autoDismiss: 5000,
          callback: () => {},
        });
        this.setProcessingAction(false);
      }
      await this.goToFormTemplateOverview();
    },
    async deleteAddedSteps() {
      const filteredSteps = this.editableSteps.filter(
        x => !this.isStepInArray(this.stepsCopy, x),
      );

      for (const step of filteredSteps)
        await this.deleteFormTemplateStep(step.id);
    },
    isStepInArray(stepsArray, step) {
      return stepsArray.findIndex(element => element.id === step.id) !== -1;
    },
    onElementDrop(event) {
      // Check for not valid element type
      if (
        !this.formTemplateElements.some(
          element => element.type === event.elementType,
        )
      )
        return;

      const stepIndex = this.editableSteps.findIndex(
        step => step.order === event.stepOrder,
      );
      if (!this.isOnEditMode)
        this.updateFormTemplateStep(this.editableSteps[stepIndex]);
    },
    onElementOrderChanged({ stepId, movedElement, targetElement }) {
      if (movedElement === targetElement)
        return;

      const stepIndex = this.stepsCopy.findIndex(step => step.id === stepId);
      const indexOfMovedElement = this.stepsCopy[stepIndex].elements.findIndex(
        element => element.id === movedElement,
      );
      const elementsWithoutMovedElement = this.stepsCopy[
        stepIndex
      ].elements.filter(element => element.id !== movedElement);
      const indexOfTargetElement = elementsWithoutMovedElement.findIndex(
        element => element.id === targetElement,
      );

      // Prevent dragging on other steps
      if (
        !this.stepsCopy[stepIndex].elements.some(
          element => element.id === movedElement,
        )
      )
        return;

      // Prevent dragging over the header element
      if (indexOfTargetElement === 0)
        return;

      elementsWithoutMovedElement.splice(
        indexOfMovedElement <= indexOfTargetElement
          ? indexOfTargetElement + 1
          : indexOfTargetElement,
        0,
        this.stepsCopy[stepIndex].elements[indexOfMovedElement],
      );

      this.stepsCopy[stepIndex].elements = [...elementsWithoutMovedElement];
    },
    generateElement(_stepIndex, elementType) {
      // TODO: Generate some stub elements using drag and drop this will be modified
      switch (elementType) {
        case 'radio-group':
        case 'select':
        case 'checkbox-group': {
          return {
            type: elementType,
            props: {
              element: {
                id: Date.now(),
                label: elementType,
                name: `${elementType.toLowerCase()}-${Date.now()}`,
                required: false,
                type: elementType,
                values: [
                  {
                    label: 'Option 1',
                    value: 'option-1',
                    selected: false,
                  },
                  {
                    label: 'Option 2',
                    value: 'option-2',
                    selected: false,
                  },
                ],
              },
            },
          };
        }
        case 'header': {
          return {
            type: elementType,
            props: {
              element: {
                id: Date.now(),
                label: elementType,
                name: `${elementType.toLowerCase()}-${Date.now()}`,
                required: false,
                type: elementType,
                subtype: 'h1',
              },
            },
          };
        }
        case 'paragraph': {
          return {
            type: elementType,
            props: {
              element: {
                id: Date.now(),
                label: elementType,
                display: 'vertical',
                name: `${elementType.toLowerCase()}-${Date.now()}`,
                text: '',
                type: elementType,
              },
            },
          };
        }
        default: {
          return {
            type: elementType,
            props: {
              element: {
                id: Date.now(),
                label: elementType,
                name: `${elementType.toLowerCase()}-${Date.now()}`,
                required: false,
                type: elementType,
              },
            },
          };
        }
      }
    },
    removeStepElement({ stepData, stepElement }) {
      const elementIndex = stepData.elements.findIndex(
        element => element.id === stepElement.id,
      );
      stepData.elements.splice(elementIndex, 1);
      this.changeStepElement(stepData);
    },
    changeStepElement(stepData) {
      const stepIndex = this.editableSteps.findIndex(
        step => step.order === stepData.order,
      );

      this.updateFormTemplateStep({ ...this.editableSteps[stepIndex], ...stepData });
    },
    onRemoveStep(stepData) {
      this.$dialog.$delete({
        title: this.__('Delete step'),
        contents: this.__('Are you sure you want to delete this step?'),
        type: 'danger',
        beforeClose: async (action, _instance, done) => {
          if (this.processingAction) {
            done();
            return;
          }
          if (action === 'confirm') {
            this.setProcessingAction(true);
            this.editableSteps.splice(stepData.order - 1, 1);
            this.recomputeStepOrder();
            try {
              await this.deleteFormTemplateStep(stepData.id);
              hj('event', 'form_template_step_delete');
              this.setProcessingAction(false);
            }
            catch {
              hj('event', 'form_template_step_delete_fail');
              this.$toasty.$error({
                title: this.__('Something went wrong'),
                message: this.__('Failed to delete step'),
                autoDismiss: 5000,
                callback: () => {},
              });
              this.setProcessingAction(false);
            }
          }
          done();
        },
      });
    },
    recomputeStepOrder() {
      for (let index = 0; index < this.editableSteps.length; index++)
        this.editableSteps[index].order = index + 1;
    },
    onMoveStep({ id, index }) {
      const stepIndex = this.editableSteps.findIndex(step => step.id === id);

      if (stepIndex === -1)
        return;

      this.editableSteps.splice(index, 0, this.editableSteps.splice(stepIndex, 1)[0]);

      this.recomputeStepOrder();
    },
  },
});
</script>

<template>
  <div class="relative mt-8 flex flex-row">
    <div class="absolute flex w-1/4 flex-col space-y-6 rounded bg-white p-6">
      <draggable
        :sort="false"
        :list="formTemplateElements"
        :group="{
          name: 'stepElements',
          pull: 'clone',
          put: false,
        }"
        :move="onMove"
        item-key="type"
        :clone="clone"
      >
        <template #item="{ element }">
          <div class="flex cursor-pointer space-x-6 p-3">
            <Icon :name="element.icon" />
            <div>
              {{ element.name }}
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div class="absolute left-1/4 flex w-3/4 flex-col space-y-6 pl-6">
      <div class="computed-height">
        <div v-auto-animate class="flex flex-col gap-6">
          <div v-for="(step, index) of editableSteps" :key="step.id">
            <FormTemplateStep
              :steps="editableSteps"
              :step-data="step"
              :can-delete="editableSteps.length > 1"
              :index="index"
              @on-step-move="onMoveStep"
              @change-step-element="changeStepElement"
              @on-element-dropped="onElementDrop"
              @remove-step="onRemoveStep"
              @remove-step-element="removeStepElement"
            />
          </div>
        </div>
      </div>

      <div class="flex w-full space-x-4">
        <div class="flex w-2/4 rounded bg-white hover:shadow-md">
          <trailblazer-button
            expand
            icon="add"
            variant="secondary"
            @click="onAddNewStep"
          >
            {{ __('Add new steps') }}
          </trailblazer-button>
        </div>
        <div class="flex w-2/4 space-x-3">
          <div
            v-if="isOnEditMode"
            class="flex w-1/3 rounded bg-white hover:shadow-md"
          >
            <trailblazer-button expand danger @click="onCancelFormEdit">
              {{ __('Cancel') }}
            </trailblazer-button>
          </div>
          <div
            class="flex w-1/2 rounded bg-white hover:shadow-md"
            :class="{ 'w-1/3': isOnEditMode }"
          >
            <trailblazer-button
              v-if="activeTemplateStatus"
              expand
              variant="secondary"
              @click="onUnpublishTemplate"
            >
              {{ __('Unpublish') }}
            </trailblazer-button>
            <trailblazer-button
              v-else
              expand
              variant="secondary"
              @click="onCloseTemplate"
            >
              {{ __('Close template') }}
            </trailblazer-button>
          </div>
          <div
            class="flex w-1/2 rounded bg-white hover:shadow-md"
            :class="{ 'w-1/3': isOnEditMode }"
          >
            <trailblazer-button
              v-if="activeTemplateStatus"
              expand
              variant="primary"
              @click="updateTemplateSteps"
            >
              {{ __('Save') }}
            </trailblazer-button>
            <trailblazer-button
              v-else
              expand
              variant="primary"
              @click="onPublishTemplate"
            >
              {{ __('Publish') }}
            </trailblazer-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.computed-height {
  max-height: calc(100vh - 321px);
  overflow: scroll;
}
</style>

<style>
.ghost {
  opacity: 0.5;
  @apply bg-primary-100;
}
</style>
