import dayjs from 'dayjs';

export function fetchTicketDate(dateToBeFormatted) {
  if (!dateToBeFormatted)
    return '-';

  return dayjs(dateToBeFormatted).format('dddd MMMM DD, YYYY - HH:mm');
}

export function fetchLimitedTicketDate(dateToBeFormatted) {
  if (!dateToBeFormatted)
    return '-';

  return dayjs(dateToBeFormatted).format('DD/MM/YYYY - HH:mm');
}

export function fetchTicketHour(ticket) {
  return dayjs(ticket.ticket_date).format('HH:mm');
}

export function formatPriority(priority) {
  const priorityName = priority ? priority.name : '';

  return priorityName ? `priority${priorityName}` : 'priorityLow';
}

export function ticketCreationDate(ticket) {
  return dayjs(ticket.ticket_date).format('dddd MMMM DD, YYYY');
}

export function ticketsForDate(tickets, ticketDate) {
  return tickets.filter(ticket => ticketCreationDate(ticket) === ticketDate);
}

export async function loadMore(tickets) {
  if (this.showLoadMore(tickets))
    await this.retrieveNextTickets();
}

export function showLoadMore(tickets) {
  return tickets && tickets.links && tickets.links.next;
}

export function getStatusColor(statusId) {
  switch (statusId) {
    case 1: {
      return 'blue';
    }
    case 2: {
      return 'yellow';
    }
    case 3: {
      return 'black';
    }
  }
}

export function convertCategoriesToOptions(types) {
  return (
    types?.map(item => ({
      id: item.id.toString(),
      label: item.name,
      hint: item.description,
      value: item.id.toString(),
      children: (item.categories || []).map(child => ({
        id: child.id.toString(),
        label: child.name,
        hint: child.description,
        value: child.id.toString(),
      })),
    })) ?? []
  );
}
