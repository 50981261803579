import { createStore } from 'vuex';

import ConfirmationModalStore from '../store/ConfirmationModalStore';
import ContextStore from '../store/ContextStore';
import CreateCommonsStore from '../store/CreateCommonsStore';
import CreateFormFlowStore from '../store/CreateFormFlowStore';
import CreateNewModal from '../store/CreateNewModal';
import CreateTicketStore from '../store/CreateTicketStore';
import FilteringStore from '../store/FilteringStore';
import FormFlowsModuleStore from '../store/FormFlowsModuleStore';
import FormTemplateModuleStore from '../store/FormTemplateModuleStore';
import LocationsStore from '../store/LocationsStore';
import MobileDropdownModalStore from '../store/MobileDropdownModalStore';
import ModalStore from '../store/ModalStore';
import RegistrationStore from '../store/RegistrationStore';
import SidebarStore from '../store/SidebarStore';
import ThemeStore from '../store/ThemeStore';
import TicketsModuleStore from '../store/TicketsModuleStore';
import TranslationStore from '../store/TranslationStore';

const modules = {
  ContextStore,
  ConfirmationModalStore,
  CreateCommonsStore,
  CreateFormFlowStore,
  CreateNewModal,
  CreateTicketStore,
  FilteringStore,
  FormFlowsModuleStore,
  FormTemplateModuleStore,
  LocationsStore,
  MobileDropdownModalStore,
  ModalStore,
  RegistrationStore,
  SidebarStore,
  ThemeStore,
  TicketsModuleStore,
  TranslationStore,
};
const initialState = {};
for (const key of Object.keys(modules))
  initialState[key] = modules[key].state();

export default defineNuxtPlugin((nuxt) => {
  const store = createStore({
    strict: false,
    modules,
    mutations: {
      reset(state) {
        for (const key of Object.keys(state))
          Object.assign(state[key], initialState[key]);
      },
    },
  });

  if (import.meta.hot) {
    import.meta.hot.accept(
      [
        '../store/ConfirmationModalStore.js',
        '../store/ContextStore.js',
        '../store/CreateCommonsStore.js',
        '../store/CreateFormFlowStore.js',
        '../store/CreateNewModal.js',
        '../store/CreateTicketStore.js',
        '../store/FilteringStore.js',
        '../store/FormFlowsModuleStore.js',
        '../store/FormTemplateModuleStore.js',
        '../store/LocationsStore.js',
        '../store/MobileDropdownModalStore.js',
        '../store/ModalStore.js',
        '../store/RegistrationStore.js',
        '../store/SidebarStore.js',
        '../store/ThemeStore.js',
        '../store/TicketsModuleStore.js',
        '../store/TranslationStore.js',
      ],
      (modules) => {
        store.hotUpdate({
          modules,
        });
      },
    );
  }

  nuxt.provide('store', store);
});
