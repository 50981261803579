function getBrowserLanguage() {
  const language
    = (window.navigator.languages && window.navigator.languages[0])
    || window.navigator.language
    || window.navigator.browserLanguage
    || window.navigator.userLanguage
    || window.navigator.systemLanguage
    || undefined;

  if (language && language.length > 2)
    return language.slice(0, 2);

  return language;
}

export default async function localeGlobal() {
  const { $auth, $store } = useNuxtApp();

  const defaultLanguage = $auth?.user.value?.language || getBrowserLanguage();

  try {
    if ($store !== undefined) {
      await $store.dispatch(
        'TranslationStore/fetchTranslations',
        defaultLanguage,
      );
    }
  }
  catch (error) {
    console.error(error);
  }
}
