const DARK_SECONDARY_MENU_BACKGROUND = '#0E161D';
const WHITE = '#FFFFFF';
const NEUTRAL_3 = '#F3F6FA';
const NEUTRAL_2 = '#A0A4A7';
const SUCCESS = '#18CC6B';
const ERROR = '#F53B3B';
const PRIMARY = '#0080FF';

export default {
  DARK_SECONDARY_MENU_BACKGROUND,
  WHITE,
  NEUTRAL_3,
  NEUTRAL_2,
  SUCCESS,
  ERROR,
  PRIMARY,
};
