export default {
  namespaced: true,
  state: () => ({
    confirmationModalOpened: false,
    confirmationModalTitle: '',
    confirmationModalMessage: '',
    confirmationModalData: {},
    confirmationModalType: '',
    confirmationModalCallbacks: [],
    confirmationExtraButtons: [],
  }),
  getters: {
    confirmationModalOpened(state) {
      return state.confirmationModalOpened;
    },
    confirmationModalTitle(state) {
      return state.confirmationModalTitle;
    },
    confirmationModalMessage(state) {
      return state.confirmationModalMessage;
    },
    confirmationModalData(state) {
      return state.confirmationModalData;
    },
    confirmationModalType(state) {
      return state.confirmationModalType;
    },
    confirmationModalCallbacks(state) {
      return state.confirmationModalCallbacks;
    },
    confirmationExtraButtons(state) {
      return state.confirmationExtraButtons;
    },
  },
  mutations: {
    setConfirmationModalOpened: (state, confirmationModalOpened) => {
      state.confirmationModalOpened = confirmationModalOpened;
    },
    setConfirmationModalTitle: (state, confirmationModalTitle) => {
      state.confirmationModalTitle = confirmationModalTitle;
    },
    setConfirmationModalMessage: (state, confirmationModalMessage) => {
      state.confirmationModalMessage = confirmationModalMessage;
    },
    setConfirmationModalData: (state, confirmationModalData) => {
      state.confirmationModalData = confirmationModalData;
    },
    setConfirmationModalType: (state, confirmationModalType) => {
      state.confirmationExtraButtons = [];
      state.confirmationModalCallbacks = [];
      state.confirmationModalType = confirmationModalType;
    },
    setConfirmationModalCallbacks: (state, callbacks) => {
      state.confirmationModalCallbacks = callbacks;
    },
    setConfirmationExtraButtons: (state, extraButtons) => {
      state.confirmationExtraButtons = extraButtons;
    },
  },
};
