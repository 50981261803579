export default {
  name: 'TicketsApiMixin',
  methods: {
    async uploadFileToTicket(file) {
      const headers = useRequestHeaders(['cookie']);

      const formData = new FormData();
      formData.append('file', file);
      return await $fetch(`/api/v2/ticket/tickets/files`, {
        method: 'POST',
        baseURL: this.$baseURL,

        body: formData,
        headers,
      });
    },
    async attachFileToTicket(ticketId, file) {
      const headers = useRequestHeaders(['cookie']);

      const formData = new FormData();
      formData.append('file', file);

      return await $fetch(`/api/v2/ticket/tickets/${ticketId}/files`, {
        method: 'POST',
        baseURL: this.$baseURL,

        body: formData,
        headers,
      });
    },
    async getTicketById(ticketId) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/ticket/tickets/${ticketId}?embed=files&include_abilities`,
        {
          baseURL: this.$baseURL,
          headers,
        },
      );
    },
    async putTicket(ticketId, ticketPayload) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/ticket/tickets/${ticketId}?embed=files&include_abilities`,
        {
          method: 'PATCH',
          baseURL: this.$baseURL,

          body: ticketPayload,
          headers,
        },
      );
    },
    async putTicketStatus(ticketId, statusId) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/ticket/tickets/${ticketId}/status?include_abilities`,
        {
          method: 'PATCH',
          baseURL: this.$baseURL,

          body: { status_id: statusId },
          headers,
        },
      );
    },
    async postTicket(ticketPayload) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/ticket/tickets?include_validation`, {
        method: 'POST',
        baseURL: this.$baseURL,

        body: ticketPayload,
        headers,
      });
    },
    async getTickets(urlParameters) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/ticket/tickets?include_validation&embed=comments.user,files`,
        {
          params: urlParameters,
          baseURL: this.$baseURL,
          headers,
        },
      );
    },

    async getByUrl(url) {
      const headers = useRequestHeaders(['cookie']);
      const { pathname, search } = new URL(url);

      return await $fetch(`${pathname}${search}`, {
        baseURL: this.$baseURL,
        headers,
      });
    },
    async getAssignees(checkGroupId) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/ticket/groups/${checkGroupId}/members`, {
        baseURL: this.$baseURL,
        query: {
          per_page: 1000,
        },
        headers,
      });
    },
    async getGroupsLocations() {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/ticket/groups/ticket-create`, {
        baseURL: this.$baseURL,
        headers,
      });
    },
    async getStatuses() {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/ticket/status`, {
        baseURL: this.$baseURL,
        headers,
      });
    },
    async getGroups() {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/ticket/tickets/groups`, {
        baseURL: this.$baseURL,
        query: {
          per_page: 1000,
        },
        headers,
      });
    },
    async getTicketGroupsForLocation(locationId) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/ticket/tickets/groups?location_id=${locationId}`,
        { baseURL: this.$baseURL, headers },
      );
    },
    async getPriorities() {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/ticket/priorities`, {
        baseURL: this.$baseURL,
        headers,
      });
    },
    async getUsers() {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/ticket/users`, {
        baseURL: this.$baseURL,
        query: {
          per_page: 1000,
        },
        headers,
      });
    },
    async getEscalations(locationId) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/ticket/escalations`, {
        baseURL: this.$baseURL,

        params: { location_id: locationId },
        headers,
      });
    },
    async getTypes(locationId, groupId) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/location/${locationId}/ticket-types?embed=categories`,
        {
          baseURL: this.$baseURL,
          params: { group_id: groupId },
          headers,
        },
      );
    },
    async getComments(ticketId, sortingDesc) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `${this.$baseURL}/api/v2/ticket/tickets/${ticketId}/comments?sort=${
          sortingDesc ? '-created_at' : 'created_at'
        }&per_page=100&include_abilities=true`,
        { baseURL: this.$baseURL, headers },
      );
    },
    async postComment(ticketId, comment) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/ticket/tickets/${ticketId}/comments`, {
        method: 'POST',
        baseURL: this.$baseURL,

        body: comment,
        headers,
      });
    },
    async updateComment(ticketId, commentId, comment) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/ticket/tickets/${ticketId}/comments/${commentId}?include_abilities`,
        {
          method: 'PATCH',
          baseURL: this.$baseURL,
          body: comment,
          headers,
        },
      );
    },
    async removeComment(ticketId, commentId) {
      const headers = useRequestHeaders(['cookie']);

      await $fetch(
        `/api/v2/ticket/tickets/${ticketId}/comments/${commentId}?include_abilities`,
        { method: 'DELETE', baseURL: this.$baseURL, headers },
      );
    },
    async deleteTicket(ticketId) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(`/api/v2/ticket/tickets/${ticketId}`, {
        method: 'DELETE',
        baseURL: this.$baseURL,

        headers,
      });
    },
  },
};
