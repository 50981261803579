import { mapGetters } from 'vuex';

import LocationsApiMixin from './LocationsApiMixin';

export default defineNuxtComponent({
  mixins: [LocationsApiMixin],
  data() {
    return {
      locationData: [],
    };
  },
  computed: {
    ...mapGetters('LocationsStore', [
      'selectedLocation',
      'groupsAndLocations',
      'locations',
      'groups',
    ]),
  },
  methods: {
    getSelectedLocation() {
      return this.selectedLocation && this.selectedLocation.id
        ? this.selectedLocation
        : undefined;
    },
    setSelectedLocation(location) {
      this.$store.commit('LocationsStore/setSelectedLocation', location);
      this.$store.dispatch('LocationsStore/fetchNFC', location);
    },
    async getLocationsOnly() {
      if (this.locations.length === 0) {
        try {
          const response = await this.getAllLocations();
          this.$store.commit('LocationsStore/setLocations', response.data);
          return response.data;
        }
        catch (error) {
          // eslint-disable-next-line node/prefer-global/process
          if (process.client) {
            this.$toasty.$error({
              title: this.__('Something went wrong'),
              message: this.__('Failed to receive Locations'),
              autoDismiss: 5000,
              callback: () => { },
            });
          }
          else {
            console.error(error);
          }
          return [];
        }
      }

      return this.locations;
    },
    async getGroupsForFilteredLocation(location) {
      const response = await this.getGroupsByLocationId(location.id);
      this.$store.commit('LocationsStore/setGroups', response.data);

      return response.data;
    },
    getGroupsForLocation(location) {
      const groups = [];
      for (const element of this.groupsAndLocations) {
        if (element.location.id === location.id)
          groups.push(element);
      }

      this.$store.commit('LocationsStore/setGroups', groups);
      return groups;
    },
  },
});
